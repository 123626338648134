// Due to needing to get this code merged in ready for the beta site
// this file may not fully conform to our usual coding conventions.
// See notes in https://tech.immediate.co.uk/browse/WCP-1228 for known issues.
// TODO: Check code against notes in WCP-1228

export default (function emailSignupUI() {
  // Remove class
  function removeClass(el, classname) {
    if (el.classList) {
      el.classList.remove(classname);
    } else {
      // eslint-disable-next-line no-template-curly-in-string
      const regex = new RegExp('(^|\\b) ${classname.split(" ").join("|")} (\\b|$)', 'gi');
      el.className = el.className.replace(regex, ' '); // eslint-disable-line no-param-reassign
    }
  }
  // Re-enable submit button
  function enableButton(button) {
    // Remove inactive class
    removeClass(button, 'email-signup__button--inactive');
    // Remove loading icon
    const icon = button.querySelector('.icon-loading');
    button.removeChild(icon);
    // Enable button
    button.disabled = false; // eslint-disable-line no-param-reassign
  }
  // Reset form
  function resetForm(form, input) {
    const errorMessage = form.querySelector('.email-signup__message--error');
    // If the error message exists
    if (errorMessage) {
      // Remove error message
      errorMessage.parentNode.removeChild(errorMessage);
      // Remove error class from input
      removeClass(input, 'email-signup__input--error');
    }
  }
  // Document ready
  function documentReady(fn) {
    if (document.readyState !== 'loading') {
      fn();
    } else {
      document.addEventListener('DOMContentLoaded', fn);
    }
  }
  // Validate email address
  function validateEmail(email) {
    // Ref: https://html.spec.whatwg.org/multipage/forms.html#valid-e-mail-address
    // eslint-disable-next-line max-len, no-useless-escape
    const re = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return re.test(email);
  }
  // Email signup form
  function emailSignUp() {
    const form = document.querySelector('.js-email-signup');

    if (!form) {
      return;
    }

    const input = form.querySelector('.js-email-signup__input');
    const button = form.querySelector('.js-email-signup__button');

    if (!input || !button) {
      return;
    }
    // When form is submitted
    form.onsubmit = function onSubmit() {
      resetForm(form, input);
      // Disable button
      if (button.disabled === false) { // Check the button is active
        // Add inactive class
        button.className += ' email-signup__button--inactive';
        // Disable button
        button.disabled = true;
        // Add loading icons
        button.insertAdjacentHTML('beforeend', '<i class="icon-loading email-signup__icon"></i>');
      }
      // Validate email
      if (validateEmail(input.value)) {
        // Here we will send the email to the external service. Mimicing success for now.
        const dataSent = true;
        // Callback
        if (dataSent === true) { // Successful form submission
          // Remove form elements
          while (form.firstChild) {
            form.removeChild(form.firstChild);
          }
           // Add success message
          let msg = '<p class="email-signup__message email-signup__message--bottom">';
          msg += 'Thanks for signing up!</p>';
          form.insertAdjacentHTML('afterend', msg);
        } else { // Unsuccessful submission
          // Add error message
          let msg = '<p class="email-signup__message email-signup__message--bottom ';
          msg += 'email-signup__message--error">There was a problem with your submission. ';
          msg += 'Please try again later.</p>';
          form.insertAdjacentHTML('afterend', msg);
        }
      } else {
        // Add error class to input
        input.className += ' email-signup__input--error';
        // Add error message
        let msg = '<p class="email-signup__message email-signup__message--error ';
        msg += 'email-signup__message--validation-error">Please enter a valid email address</p>';
        input.insertAdjacentHTML('afterend', msg);
      }
      // Re-enable submit button
      enableButton(button);
      // Don't send the form
      return false;
    };
  }
  // Init
  const init = function init() {
    documentReady(emailSignUp);
  };
  return {
    init,
  };
}());
