import { adManager } from '@immediate_media/advertising';
import { isTabletDown } from 'js/utils';
import pubSub from 'pubsub-js';

export const events = {
  AD_BANNER_UI_HEADER_HEIGHT_CHANGE: 'AD_BANNER_UI:HEADER_HEIGHT_CHANGE',
  AD_BANNER_UI_BANNER_AD_HEIGHT_CHANGE: 'AD_BANNER_UI:BANNER_AD_HEIGHT_CHANGE',
};

export default (() => {
  const defaults = {
    css: {
      selectors: {
        bannerAdContainer: '.js-ad-banner-container',
        bannerAd: '.js-ad-banner-wrapper',
        bannerAdWrapper: '.js-ad-banner-wrapper',
        bannerAdDismiss: '.js-ad-banner-dismiss',
      },
    },
  };

  let settings;
  let adSlotEmpty = true;
  let bannerAd;
  let bannerAdContainer;
  let bannerAdWrapper;
  let bannerAdDismiss;         // Should the nav be sticking?

  /**
   * Sets dismissal button's visibility as appropriate.
   */
  const updateDismissalButton = function updateDismissalButton() {
    if (!bannerAdDismiss) return;
    // Show dismissal button if appropriate.
    if (window.pageYOffset > 0) {
      bannerAdDismiss.classList.add('is-active');
    } else {
      bannerAdDismiss.classList.remove('is-active');
    }
  };

  /**
   * Dismisses the banner ad.
   */
  const dismissBanner = function dismissBanner() {
    bannerAdContainer.style.display = 'none';
    updateDismissalButton();
  };

  /**
   * Set ad slot visible if it is not empty and listen to scroll to update dismissal button
   * Set up click listener for dismiss button
   */
  const setupMobile = function setupMobile() {
    adManager.addEventListener('slotRenderEnded', (adSlot) => {
      adSlotEmpty = adSlot.isEmpty;
      if (!adSlotEmpty) {
        bannerAdWrapper.style.visibility = 'visible';
      }
    });
    // Calculate positions.
    updateDismissalButton();

    // Recalculate positions on page scroll.
    document.addEventListener('scroll', () => {
      updateDismissalButton();
    });

    // Click listener for banner ad dismissal button.
    if (!bannerAdDismiss) return;
    bannerAdDismiss.addEventListener('click', () => {
      dismissBanner();
    });
  };

  /**
   * Set ad slot visible if it is not empty and listen to scroll to update dismissal button
   * Apply ad wrapper styling accordingly
   */
  const setupDesktop = function setupDesktop() {
    bannerAdWrapper.style.visibility = 'hidden';
    adManager.addEventListener('slotRenderEnded', (adSlot) => {
      adSlotEmpty = adSlot.isEmpty;
      if (!adSlotEmpty) {
        bannerAdWrapper.style.visibility = 'visible';
      }
    });

    // The .banner-ad-wrapper element only needs to wrap the banner ad on mobile, so on desktop
    // it is made full-width and its contents centered to ensure that banner ads remain centered.

    // Commenting out incase revert required - Seems to be setting desktop banners to 0px height
    // bannerAdContainer.style.height = `${bannerAdWrapper.clientHeight}px`;
    bannerAdWrapper.style.left = '0';
    bannerAdWrapper.style.right = '0';
    bannerAdWrapper.style.textAlign = 'center';
  };

  /**
   * Updates the height of the banner ad element and its containers.
   */
  const updateBannerAdHeight = function refreshAdHeight(finalHeight) {
    // set final banner styling and fire event
    bannerAd.style.height = `${finalHeight}px`;
    bannerAdWrapper.style.height = `${finalHeight}px`;
    bannerAdContainer.style.height = `${finalHeight}px`;

    pubSub.publish(events.AD_BANNER_UI_HEADER_HEIGHT_CHANGE, { finalHeight });
  };

  /**
   * Returns the ID of the banner ad.
   * @returns {String} id
   */
  const getBannerAdId = function getBannerAdId() {
    return bannerAd.id;
  };

  /**
   * Init module with given options.
   * Set up element references.
   *
   * @param {Object} [options={}] - overrides for the default settings object.
   */
  const init = function init(options = {}) {
    settings = Object.assign(defaults, options);

    // Set up element selectors.
    bannerAd = document.querySelector(settings.css.selectors.bannerAd);
    bannerAdContainer = document.querySelector(settings.css.selectors.bannerAdContainer);
    bannerAdWrapper = document.querySelector(settings.css.selectors.bannerAdWrapper);
    bannerAdDismiss = document.querySelector(settings.css.selectors.bannerAdDismiss);

    // Exit gracefully if a required element is missing.
    if (!bannerAd || !bannerAdContainer || !bannerAdWrapper) {
      return false;
    }

    // This could be wrapped in matchMedia queries to work responsively.
    if (isTabletDown()) {
      setupMobile();
    } else {
      setupDesktop();
    }

    return true;
  };

  return {
    events,
    init,
    getBannerAdId,
    updateBannerAdHeight,
  };
})();
