/**
 * Scrolls to a given element.
 *
 */
export default (() => {
  const settings = {
    padding: -10,
    selectors: {
      header: '.site-header',
    },
    stickyElements: {
      headerEyebrow: '.site-header__eyebrow',
      headerNav: '.site-header__nav',
    },
    css: {
      sticky: 'is-sticky',
    },
  };

  const supportsSmoothScrolling = () => 'scrollBehavior' in document.documentElement.style;

  const isTabletDownSticky = header => header.classList.contains(settings.css.sticky);

  const isDesktopSticky = header => header.querySelectorAll(`.${settings.css.sticky}`).length > 0;

  const getHeaderOffset = () => {
    const header = document.querySelector(settings.selectors.header);

    if (isTabletDownSticky(header)) {
      return header.offsetHeight;
    }

    const headerNav = document.querySelector(settings.stickyElements.headerNav);
    const headerEyebrow = document.querySelector(settings.stickyElements.headerEyebrow);
    const headerNavHeight = headerNav ? headerNav.offsetHeight : 0;
    const headerEyebrowHeight = headerEyebrow ? headerEyebrow.offsetHeight : 0;

    if (isDesktopSticky(header)) {
      return headerNavHeight + headerEyebrowHeight;
    }

    return (headerNavHeight * 2) + headerEyebrowHeight;
  };

  const ieSmoothScrolling = (elementY) => {
    const duration = 700;
    const startingY = window.pageYOffset;
    const diff = elementY - startingY;
    // eslint-disable-next-line max-len
    const easing = t => ((t < 0.5) ? (4 * t * t * t) : (((t - 1) * ((2 * t) - 2) * ((2 * t) - 2)) + 1));

    let start;

    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;
      const time = timestamp - start;
      let percent = Math.min(time / duration, 1);
      percent = easing(percent);

      window.scrollTo(0, startingY + (diff * percent));
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    });
  };

  const calculateScrollingDistance = (element) => {
    const bodyRect = document.body.getBoundingClientRect().top;
    const elemRect = element.getBoundingClientRect().top;

    return ((elemRect - bodyRect - getHeaderOffset()) + settings.padding);
  };

  const scrollToElement = (element) => {
    const scrollingDistance = calculateScrollingDistance(element);

    if (supportsSmoothScrolling()) {
      window.scroll({
        top: scrollingDistance,
        behavior: 'smooth',
      });
      return;
    }
    ieSmoothScrolling(scrollingDistance);
  };

  return {
    scrollToElement,
  };
})();
