import Pikaday from 'pikaday';
import { createUIMap } from 'js/utils';

/**
 * Attaches a datepicker UI to a specified input fields.
 *
 * By default, the datepicker will be attached to the first input field in a
 * container with the given JavaScript handler. A click event listener will be
 * attached to the first button element found in the container.
 * @module components/Datepicker-ui
 * @see module:view-model/commonVM
 */
export default {
  uimap: createUIMap({
    container: '.js-datepicker',
  }),

  /**
   * Get the Pickaday settings.
   *
   * Most of these settings are configurable via data attributes so that we
   * can configure the component via the Twig templates. Ideally, we would
   * encapsulate all of this logic inside the component; this is a workaround
   * until we have JavaScript components.
   * @param {HTMLElement} containerEl
   *   A container element.
   * @param {HTMLElement} inputEl
   *   An input element.
   * @returns {Object}
   *   The Pickaday settings.
   */
  getSettings(containerEl, inputEl) {
    const settings = {
      field: inputEl,

      i18n: {
        previousMonth: 'Previous Month',
        nextMonth: 'Next Month',
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        weekdaysShort: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      },

      format: 'format' in inputEl.dataset
        ? inputEl.dataset.format
        : 'DD/MM/YYYY',

      yearRange: 'yearrange' in inputEl.dataset
        ? parseInt(inputEl.dataset.yearrange, 10)
        : 99,

      position: 'position' in inputEl.dataset
        ? inputEl.dataset.position
        : 'bottom left',

      minDate: 'mindate' in inputEl.dataset
        ? new Date(inputEl.dataset.mindate)
        : null,

      maxDate: 'maxdate' in inputEl.dataset
        ? new Date(inputEl.dataset.maxdate)
        : null,

      setDefaultDate: 'setdefaultdate' in inputEl.dataset
        ? inputEl.dataset.setdefaultdate === 'true'
        : true,

      firstDay: 'firstday' in inputEl.dataset
        ? parseInt(inputEl.dataset.firstday, 10)
        : 1,

      defaultDate: 'defaultdate' in inputEl.dataset
        ? new Date(inputEl.dataset.defaultdate)
        : new Date(),
    };

    // So that we can use data-container="" to specify no container
    try {
      settings.container = 'container' in inputEl.dataset
        ? document.querySelector(inputEl.dataset.container)
        : containerEl;
    } catch (e) {
      settings.container = null;
    }

    return settings;
  },

  /**
   * Get a datepicker.
   * @param {HTMLElement} [containerEl]
   *   A container element to attach the datepicker to.
   * @param {HTMLElement} [inputEl]
   *   An input element.
   * @param {Object} [options]
   *   Options to pass to Pikaday.
   * @returns {Object}
   *   A Pickaday datepicker.
   */
  getDatePicker(containerEl, inputEl, options) {
    const settings = this.getSettings(containerEl, inputEl);
    const mergedSettings = Object.assign(settings, options || {});
    return new Pikaday(mergedSettings);
  },

  /**
   * Add event listeners to the datepicker button.
   * @param {HTMLElement} [containerEl]
   *   The password toggle container element.
   * @param {Object} [options]
   *   Options to pass to Pikaday.
   */
  addEventListeners(containerEl, options) {
    const buttonEl = containerEl.getElementsByTagName('button')[0];
    const inputFieldEl = containerEl.getElementsByTagName('input')[0];

    const datepicker = this.getDatePicker(containerEl, inputFieldEl, options);

    // Attach the datepicker click handler to the container element.
    // eslint-disable-next-line no-param-reassign
    containerEl.show = datepicker.show.bind(datepicker);

    if (buttonEl) {
      buttonEl.addEventListener('click', containerEl.show);
      window.addEventListener('beforeunload', () => {
        buttonEl.removeEventListener('click', containerEl.show);
      });
    }
  },

  /**
   * Initialise.
   * @param {HTMLElement} [element]
   *   An optional element to initialise a datepicker for.
   * @param {Object} [options]
   *   Options to pass to Pikaday.
   */
  init(element, options) {
    const elements = element ? [element] : this.uimap.$$container;
    elements.forEach((el) => {
      this.addEventListeners(el, options);
    });
  },
};
