/**
 * Permutive activator.
 * Activates Permutive based on users consent
 */

import { waitForConsentChange, checkPurposes } from '@immediate_media/consent-checker';

window.IM = window.IM || {};

/**
 * Push the permutive PageView event.
 */
const setPermutiveAddon = () => {
  const permutiveModel = window.IM.PermutiveModel;

  if (!permutiveModel) {
    return;
  }

  if (!window.permutive.addon) {
    throw new Error([
      'The permutive SDK is unavailable, please check that the permutive',
      'script has been added to the page and was run successfully',
      '(see https://developer.permutive.com/page/the-permutive-javascript-sdk)',
    ].join(' '));
  }

  window.permutive.addon('web', permutiveModel);
};

export default (() => ({
  init() {
    if (!window.permutive) {
      return;
    }

    /**
     * waitForConsentChange, and on return, check if the user has rejected consent purposes or not
     * If they have rejected purpose 1,3,5,7,9,10, and/or the vendor has been rejected, then remove
     * If they have accepted 1,3,5,7,9,10, and the vendor has been accepted, then allow
     */
    waitForConsentChange(() => {
      if (checkPurposes([1, 3, 5, 7, 8, 9, 10], [361], []) === true) {
        window.permutive.consent({ opt_in: true, token: 'CONSENT_CAPTURED' });
        setPermutiveAddon();
      } else {
        window.permutive.consent({ opt_in: false });
      }
    });
  },
}))();
