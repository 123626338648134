/**
 * A very basic check for supported browsers.
 * @returns {boolean}
 *   True if the browser is supported, false otherwise.
 */
export default () => (
  'querySelector' in document
  || 'localStorage' in window
  || 'addEventListener' in window
);
