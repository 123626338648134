/**
 * Adds event handler for clicking on the mobile navigation to show it open or closed
 * @module components/navigationUI
 * @see module:view-model/commonVM
 * @author Steve gibbings <steve.gibbings@immediate.co.uk>
 * @version 1.0
 */
import Modernizr from 'modernizr';
import FocusWithin from '../polyfills/focuswithin';

export default (function NavigationUI() {
  const defaults = {
    css: {
      parent: '',
      selectors: {
        navContainer: '.js-nav-container',
        navActivator: '.js-nav-activator',
        nav: '.js-nav',
        subNavBack: '.back-link',
        subNavBackThird: '.back-link-third',
        menuItem: '.js-nav-item-has-children',
        levelOneOpenState: '.is-sub-menu-open',
        levelTwoOpenState: '.level-2.has-children.is-open',
        quickLinks: '.nav__quick-links',
        buttonClusterLinks: '.site-header__custom-links--in-menu',
      },
      classes: {
        isOpen: 'is-open',
        isActive: 'is-active',
        isSubMenuOpen: 'is-sub-menu-open',
        thirdTier: 'has-children',
      },
    },
  };

  let settings;
  let navActivator;
  let navContainer;
  let nav;
  let menuItems;
  let menuActive;
  let quickLinks;
  let buttonClusterLinks;

  const toggleSubMenu = function toggleSubMenu(event) {
    const searchBlock = document.querySelector(settings.css.selectors.navActivator);
    const subNavBack = document.querySelector(settings.css.selectors.subNavBack);
    const subNavBackThird = document.querySelector(settings.css.selectors.subNavBackThird);
    if (Array.from(menuItems).includes(event.target.parentElement)) {
      if (!event.target.parentElement.classList.contains(settings.css.classes.isSubMenuOpen)) {
        // If the menu has children and it's not open then we avoid to go to the link
        event.preventDefault();
        if (searchBlock) {
          searchBlock.scrollIntoView(true);
        } else {
          subNavBack.scrollIntoView(true);
        }
        if (buttonClusterLinks) buttonClusterLinks.classList.add('hidden');
        if (quickLinks) quickLinks.classList.add('hidden');
      }
      // If there is an active menu then close it and save the current as active
      if (menuActive) event.preventDefault();

      menuActive = event.target.parentElement;
      // Toogle the current menu class
      event.target.parentElement.classList.toggle(settings.css.classes.isSubMenuOpen);
    }
    // handle third tier
    if (event.target.parentElement.classList.contains(settings.css.classes.thirdTier)) {
      event.preventDefault();
      if (searchBlock) {
        searchBlock.scrollIntoView(true);
      } else {
        subNavBackThird.scrollIntoView(true);
      }
      event.target.parentElement.classList.toggle(settings.css.classes.isOpen);
    }
  };

  const init = function init(options) {
    settings = Object.assign(defaults, options);

    if (!Modernizr.focuswithin) {
      FocusWithin.init();
    }

    // Wrap the CSS selectors in the parent if one exists
    if (settings.css.parent || settings.css.parent.length > 0) {
      Object.keys(settings.css.selectors).forEach((key) => {
        const prop = settings.css.selectors[key];
        settings.css.selectors[key] = `${settings.css.parent} ${prop}`;
      });
    }

    // Grab references to the elements
    nav = document.querySelector(settings.css.selectors.nav);
    navActivator = document.querySelector(settings.css.selectors.navActivator);
    navContainer = document.querySelector(settings.css.selectors.navContainer);
    quickLinks = document.querySelector(settings.css.selectors.quickLinks);
    buttonClusterLinks = document.querySelector(settings.css.selectors.buttonClusterLinks);

    // Check all elements exist
    if (!nav || !navActivator || !navContainer) {
      return;
    }

    navActivator.addEventListener('click', () => {
      // On click toggle the classes
      nav.classList.toggle(settings.css.classes.isOpen);
      navContainer.classList.toggle(settings.css.classes.isOpen);

      // If menu closes when active on sub tier, cleanup to go back to original state
      if (!navContainer.classList.contains(settings.css.classes.isOpen)) {
        const levelOneOpenState = document
          .querySelector(settings.css.selectors.levelOneOpenState);
        const levelTwoOpenState = document
          .querySelector(settings.css.selectors.levelTwoOpenState);
        if (buttonClusterLinks) buttonClusterLinks.classList.remove('hidden');
        if (quickLinks) quickLinks.classList.remove('hidden');
        if (levelOneOpenState) {
          levelOneOpenState.classList.remove(settings.css.classes.isSubMenuOpen);
        }
        if (levelTwoOpenState) levelTwoOpenState.classList.remove(settings.css.classes.isOpen);
      }
    });

    // Grab references to menu items with sub navs but delegate events to the parent of them.
    menuItems = Array.from(document.querySelectorAll(settings.css.selectors.menuItem));

    const navLeftEdge = navContainer.getBoundingClientRect().left;

    // Any menu items that has subpages...
    Object.keys(menuItems).forEach((item) => {
      const navSwitchAlignPoint = 440;
      const navItemOffset = menuItems[item].offsetLeft;
      const level2NavItems = Array.from(menuItems[item].querySelectorAll('.level-2'));
      const level3NavItems = Array.from(menuItems[item].querySelectorAll('.level-3'));
      let ariaExpandedMobile = false;

      const currentNavItemLeftEdge = menuItems[item].getBoundingClientRect().left;
      const overflowFixRightAlign = currentNavItemLeftEdge - navLeftEdge; // amount to fix by

      // only nav items in the center area of nav have potencial to "over-run"
      if (overflowFixRightAlign > 300 && overflowFixRightAlign < 650) {
        menuItems[item].classList.add('near-midpoint');
      }


      const subPageInfo = {
        secondTiers: level2NavItems.length,
        thirdTiers: level3NavItems.length,
        totalSubpages: level2NavItems.length + level3NavItems.length,
      };

      if (window.innerWidth < 992) {
        menuItems[item].addEventListener('click', () => {
          menuItems[item].setAttribute('aria-expanded', ariaExpandedMobile = !ariaExpandedMobile);
        });
      } else {
        menuItems[item].addEventListener('mouseover', () => {
          menuItems[item].setAttribute('aria-expanded', 'true');
        });
        menuItems[item].addEventListener('mouseout', () => {
          menuItems[item].setAttribute('aria-expanded', 'false');
        });
      }

      if (navItemOffset > navSwitchAlignPoint) {
        menuItems[item].classList.add('flys-left');
      }

      // we only need these classes added on desktop...
      if (subPageInfo.totalSubpages < 22 && window.innerWidth > 992) {
        if (subPageInfo.totalSubpages > 7 &&
          subPageInfo.secondTiers > 1 && subPageInfo.totalSubpages <= 14) {
          menuItems[item].classList.add('colmetwice');
        } else if (subPageInfo.totalSubpages > 14 && subPageInfo.totalSubpages <= 21) {
          menuItems[item].classList.add('colmethrice');
        } else if (subPageInfo.totalSubpages <= 7) {
          menuItems[item].classList.add('colmeonce');
        }
      } else {
        menuItems[item].classList.add('fullhouse');
      }
      if (window.innerWidth > 992) {
        if (subPageInfo.secondTiers <= 3 && subPageInfo.thirdTiers > 14) {
          menuItems[item].classList.add('colmethrice');
        } else if (subPageInfo.secondTiers <= 3 && subPageInfo.thirdTiers < 8) {
          menuItems[item].classList.add('colmeonce');
        } else if (subPageInfo.secondTiers === 1 && subPageInfo.thirdTiers > 6) {
          menuItems[item].classList.add('colmeonce');
        }
      }
    });

    if (window.innerWidth < 992 && menuItems.length) {
      // Adding click event
      menuItems[0].parentElement.addEventListener('click', toggleSubMenu);
    }

    Array.from(document.querySelectorAll(settings.css.selectors.subNavBack)).forEach((item) => {
      item.addEventListener('click', (event) => {
        event.target.parentElement.parentElement.parentElement
          .parentElement.parentElement.classList.remove(settings.css.classes.isSubMenuOpen);
        if (buttonClusterLinks) {
          buttonClusterLinks.classList.remove('hidden');
        }
        if (quickLinks) {
          quickLinks.classList.remove('hidden');
        }
      });
    });
    Array.from(document.querySelectorAll(settings.css.selectors.subNavBackThird))
      .forEach((item) => {
        item.addEventListener('click', (event) => {
          event.target.parentElement.parentElement.parentElement
            .classList.remove(settings.css.classes.isOpen);
        });
      });
  };

  return {
    /**
     * Initialises the component: attaches a click event handler to add and remove CSS classes
     * @function
     * @param {Object} options - to override the internal defaults
     */
    init,
  };
}());
