/**
 * Suppresses focus styling when user clicks on a given item, but continues to display it
 * if the item is tabbed to using keyboard.
 *
 * This cannot be achieved with just CSS because CSS does not distinguish between keyboard
 * focus and click focus. The resulting JS is a bit hacky but unfortunately I couldn't find
 * a cleaner way to achieve it.
 *
 * @module components/focus-border-suppress-ui.js
 * @see module:view-model/common-vm
 * @author Rohan Milton
 * @version 1.0
 */

export default (() => {
  const defaults = {
    targets: '.js-supress-focus-on-click',
  };

  let settings;

  const init = (options) => {
    settings = Object.assign({}, defaults, options);

    // grab target elements
    const targetItems = Array.from(document.querySelectorAll(settings.targets));

    // for each target element
    targetItems.forEach((target) => {
      let mousedown;
      let focusedEl;

      /**
      * focusedEl must contain reference to element that receives focus when the target is clicked.
      * Typically this is same as target, except in case of a label in which case it's the
      * corresponding input.
      */
      if (target.nodeName === 'LABEL') {
        const forAttr = target.getAttribute('for');
        /**
        * If target has "for" attribute, use it to get corresponding input. If not, get the
        * an input nested in the label.
        */
        focusedEl = forAttr ? document.getElementById(forAttr) : target.querySelector('input');
      } else {
        focusedEl = target;
      }

      if (target && focusedEl) {
        /**
        * To differentiate between a 'click' event fired by the keyboard and one fired by mouse,
        * set a variable to true when mousedown event fires
        */
        target.addEventListener('mousedown', () => {
          mousedown = true;
        });

        focusedEl.addEventListener('click', () => {
          if (mousedown) {
            // blur element to remove focus styling
            document.activeElement.blur();
            mousedown = false;
          }
        });
      }
    });
  };
  return {
    init,
  };
})();
