/**
 * Create a UI map with shortcuts to access associated DOM elements.
 * @param {Object} selectors
 *   Some key-selector pairs used to create the UI map.
 * @param {Element} [baseElement]
 *   Optional base element under which to check for the selectors.
 * @returns {Object}
 *   A UI map where the original selector can be accessed with uimap.key, the
 *   first element that matches that selector with uimap.$key and all elements
 *   that match that selector with uimap.$$key.
 */
export default (selectors, baseElement) => {
  const uimap = Object.assign({}, selectors);
  const root = baseElement || document;

  Object.keys(selectors).forEach((key) => {
    Object.defineProperty(uimap, `$${key}`, {
      get: () => root.querySelector(selectors[key]),
      enumerable: true,
    });
    Object.defineProperty(uimap, `$$${key}`, {
      get: () => Array.from(root.querySelectorAll(selectors[key])),
      enumerable: true,
    });
  });

  return uimap;
};
