/**
 * Entry point to initialise all common im-fabric javascript
 * <br/>
 * {@link https://github.immediate.co.uk/WCP/im-fabric/blob/develop/src/js/view-model/common-vm.js}
 *
 * @module view-model/common-vm
 * @author Steve Gibbings <steve.gibbings@immediate.co.uk>
 * @version 1.0
 */
// import Modernizr from 'modernizr';
// import loadCss from 'fg-loadcss';

import { waitForConsent } from '@immediate_media/consent-checker';

import pubSub from 'pubsub-js';
import permutiveActivator from '../modules/permutive-activator';
import iconHandler from '../modules/icon-handler';
import brightcoveGTM from '../modules/brightcove-gtm';
import navigationUI from '../components/navigation-ui';
import headerUI from '../components/header-ui';
import adBannerUI, { events as bannerUIEvents } from '../components/ad-banner-ui';
import socialShareUI from '../components/social-share-ui';
import betaBannerUI from '../components/beta-banner-ui';
import emailSignupUI from '../components/email-signup-ui';
import userControlsUI from '../components/user-controls-ui';
import carouselUI from '../components/carousel-ui';
import counterUI from '../components/counter-ui';
import focusBorderSupress from '../components/focus-border-supress-ui.js';
import premiumContent from '../components/premium-content-ui';
import searchboxUI from '../components/searchbox-ui';
import navigationSearchUI from '../components/navigation-search-ui';
import collapseUI from '../components/collapse-ui';
import passwordToggleUI from '../components/Password-Toggle-ui';
import datepickerUI from '../components/Datepicker-ui';
import skipLinkUI from '../components/skip-link-ui';
import lazyLoadImages from '../components/lazy-load-images';
import memberStatusChange from '../modules/member-status-change';

const defaults = {
  disableHeaderUI: false,
};

window.withConsent = window.withConsent || waitForConsent;

/* eslint-disable global-require */
window.React = require('react');
window.ReactDOM = require('react-dom');
/* eslint-enable */

export default (() => {
  const init = (options) => {
    const body = document.getElementsByTagName('body')[0];
    let pageCommonSettings;
    try {
      pageCommonSettings = JSON.parse(
        document.querySelector('body').dataset.pageCommonSettings,
      );
    } catch (error) {
      pageCommonSettings = {};
    }
    const settings = Object.assign(defaults, pageCommonSettings, options);
    if ('querySelector' in document
      && 'localStorage' in window
      && 'addEventListener' in window) {
      // bootstrap the javascript application
      // TODO: Sort how we are going to load grunticon icons
      /*
       This code was included to async load fallbacks for icons when the data.svg.css
       was the default by being link'd in the header
       As the icon-search needed two colours for the normal and hover state I used
       the enhanceSVG:true option in grunticon to embed an SVG element and use
       css to colour it. */
      /* Modernizr.on('datauri', function(hasDataURI) {
       const isIE = document.documentMode || false;

       if (hasDataURI && isIE) {
       loadCss('../../css/icons.data.png.min.css');
       } else if (!hasDataURI){
       loadCss('../../css/icons.fallback.min.css');
       }
       }); */
      permutiveActivator.init();
      iconHandler.init();
      brightcoveGTM.init();
      memberStatusChange.init();
      betaBannerUI.init();
      navigationUI.init();
      userControlsUI.init();
      carouselUI.init();
      counterUI.init();
      focusBorderSupress.init();
      premiumContent.init();
      searchboxUI.init();
      navigationSearchUI.init();
      collapseUI.init();
      passwordToggleUI.init();
      datepickerUI.init();
      skipLinkUI.init();
      lazyLoadImages.init();
      adBannerUI.init();

      if (!settings.disableHeaderUI) {
        headerUI.init();

        pubSub.subscribe(bannerUIEvents.HEADER_UI_BANNER_AD_HEIGHT_CHANGE,
          (eventName, { finalHeight }) => {
            adBannerUI.updateBannerAdHeight(finalHeight);
          });
      }
      socialShareUI.init();
      emailSignupUI.init();

      // TODO refactor approach to VM's  (multiple entry points)
      const isRecipe = body.classList.contains('single-recipe');
      const isSearchUniversal = body.classList.contains('search-universal');
      const isPage = body.classList.contains('page');
      const isCompetition = body.classList.contains('single-competition');
      const isVenue = body.classList.contains('single-venue');
      const isList = body.classList.contains('single-list');
      const isGlossary = body.classList.contains('single-glossary-item');
      const isArchive = body.classList.contains('archive');

      const hasDueDateCalculatorWidget = document.querySelector('.js-due-date-calculator');
      const hasOvulationCalculatorWidget = document.querySelector('.js-ovulation-calculator');
      const hasNewsletterSignUpWidget = document.querySelector('.widget-newsletter-sign-up');
      const hasRecommendations = document.querySelector('.recommendationswidget');
      const hasRelatedContent = document.querySelector('.js-dynamic-related-content');
      const hasWeightVolumeConverter = document.querySelector('.js-weight-volume-calculator');
      const hasRoastCalculator = document.querySelector('.js-roast-calculator');
      const hasReactions = document.querySelector('.js-reactions-pocket');
      const hasAvatar = document.querySelector('#avatar-box');

      // These two user settings VMs should likely be merged at some point
      const isUserSettings = body.classList.contains('user-settings');
      const isAdditionalUserSettings = document.querySelector('.js-additional-user-settings');

      let moduleToLoad;
      const moduleToLoadWidget = [
        {
          selector: hasDueDateCalculatorWidget,
          vm: 'due-date-calculator-widget-vm',
        },
        {
          selector: hasOvulationCalculatorWidget,
          vm: 'ovulation-calculator-widget-vm',
        },
        {
          selector: hasNewsletterSignUpWidget,
          vm: 'newsletter-signup-widget-vm',
        },
        {
          selector: hasRecommendations,
          vm: 'recommended-vm',
        },
        {
          selector: hasRelatedContent,
          vm: 'dynamic-related-vm',
        },
        {
          selector: hasReactions,
          vm: 'reactions-vm',
        },
        {
          selector: hasAvatar,
          vm: 'avatar-vm',
        },
        {
          selector: hasWeightVolumeConverter,
          vm: 'weight-volume-converter-vm',
        },
        {
          selector: hasRoastCalculator,
          vm: 'roast-calculator-vm',
        },
      ];

      if (isRecipe) {
        moduleToLoad = 'recipe-vm';
      } else if (isSearchUniversal) {
        moduleToLoad = 'search-universal-vm';
      } else if (isUserSettings) {
        moduleToLoad = 'user-settings-vm';
      } else if (isAdditionalUserSettings) {
        moduleToLoad = 'additional-user-settings-vm';
      } else if (isPage) {
        moduleToLoad = 'page-vm';
      } else if (isCompetition) {
        moduleToLoad = 'competition-vm';
      } else if (isVenue) {
        moduleToLoad = 'venue-vm';
      } else if (isArchive) {
        moduleToLoad = 'archive-vm';
      } else if (isList) {
        moduleToLoad = 'list-vm';
      } else if (isGlossary) {
        moduleToLoad = 'glossary-vm';
      } else {
        moduleToLoad = 'article-vm';
      }

      moduleToLoadWidget.forEach((item) => {
        if (item.selector) {
          import(`./${item.vm}`)
            .then((module) => {
              module.default.init();
            })
            .catch((error) => {
              throw (error);
            });
        }
      });

      if (moduleToLoad) {
        import(`./${moduleToLoad}`)
          .then((module) => {
            module.default.init();
          })
          .catch((error) => {
            throw (error);
          });
      }

      window.addEventListener('load', () => {
        // We may need to think about a catch to see if URL contains a gallery hook to execute
        // loading asap.
        const hasLightbox = body.classList.contains('js-has-gallery');

        if (hasLightbox) { // reverse - this is just for loading while we don't have class
          import('./lightbox-vm')
            .then((module) => {
              module.default.init();
            })
            .catch((error) => {
              throw (error);
            });
        }
      });
    }
  };

  return {
    /**
     * Initialise the view model
     * @function
     * @param {Object} [options] - override defaults and any
     * data-page-common-settings from the body element.
     * <br/>
     * data-page-common-settings can be set by twig templates that
     * extend base.twig by using the Twig "set" command.
     * <br/>
     * <pre><code>
     * {% extends "base.twig" %}
     * {% set page_common_settings = "{'disableHeaderUI': true}" %}
     * </code></pre>
     * <br/>
     */
    init,
  };
})();
