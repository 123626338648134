/**
 * Deals with AJAX loading the user controls markup
 * @module components/user-controls-ui
 * @see module:view-model/commonVM
 * @author Steve gibbings <steve.gibbings@immediate.co.uk>
 * @version 1.0
 */

export default (function userControlsUI() {
  const defaults = {
    url: '/api/user-logged-in',
    css: {
      selectors: {
        container: 'js-login-control-container',
        userControlNonce: 'js-login-controls-nonce',
        dropdown: 'js-login-controls-dropdown',
      },
      classes: {
        open: 'is-open',
        isLoggedIn: 'is-logged-in',
      },
    },
  };

  let settings;
  let container;
  let containerLoggedIn;
  let dropdown;
  let nonce;
  let bodyTag;
  let loginCookie;
  let loginCookieVal;

  const attachMenuListener = function attachMenuListener() {
    container.addEventListener('click', (e) => {
      // if click was outside the dropdown card
      if (!dropdown.contains(e.target)) {
        // if dropdown is expanded
        if (container.classList.contains(settings.css.classes.open)) {
          container.classList.remove(settings.css.classes.open);
        } else {
          container.classList.add(settings.css.classes.open);
        }
      }
    });
    containerLoggedIn.addEventListener('click', (e) => {
      // if click was outside the dropdown card
      if (!dropdown.contains(e.target)) {
        // if dropdown is expanded
        if (containerLoggedIn.classList.contains(settings.css.classes.open)) {
          containerLoggedIn.classList.remove(settings.css.classes.open);
        } else {
          containerLoggedIn.classList.add(settings.css.classes.open);
        }
      }
    });
  };

  const init = function init(options) {
    settings = Object.assign(defaults, options);

    nonce = document.getElementsByClassName(settings.css.selectors.userControlNonce)[0];
    container = document.getElementsByClassName(settings.css.selectors.container)[0];
    containerLoggedIn = document.getElementsByClassName(settings.css.selectors.container)[1];
    dropdown = document.getElementsByClassName(settings.css.selectors.dropdown)[0];
    bodyTag = document.getElementsByTagName('body')[0];

    // Add Guard conditions to ensure required DOM Elements exist
    if (!container || !nonce) {
      return;
    }

    loginCookie = document.cookie.match('(^|;)\\s*IMOLOGIN\\s*=\\s*([^;]+)');

    if (loginCookie === null) {
      // create a Promise for the AJAX request
      const loginPromise = new Promise((resolve, reject) => {
        const loginhttp = new XMLHttpRequest();
        loginhttp.open('POST', settings.url, true);

        loginhttp.onload = function onload() {
          if (loginhttp.status === 200) {
            resolve(loginhttp.response);
          } else {
            container.removeAttribute('hidden');
            reject(Error(loginhttp.statusText));
          }
        };

        loginhttp.onerror = function onerror() {
          container.removeAttribute('hidden');
          reject(Error('Network Error'));
        };

        loginhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        loginhttp.send(`n=${nonce.value}`);
      });
      loginPromise.then((response) => {
        const jsonResponse = JSON.parse(response);

        if (jsonResponse.isLoggedIn) {
          bodyTag.classList.add('openid-connect-logged-in');
          containerLoggedIn.removeAttribute('hidden');
        } else {
          container.removeAttribute('hidden');
        }

        attachMenuListener();
      }, () => { },
      );
    } else {
      loginCookieVal = loginCookie.pop();
      if (loginCookieVal === '1') {
        bodyTag.classList.add('openid-connect-logged-in');
        containerLoggedIn.removeAttribute('hidden');
      } else {
        container.removeAttribute('hidden');
      }
      attachMenuListener();
    }
  };

  return {
    init,
  };
}());
