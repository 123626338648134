/**
 * Creates a countdown for expiry dates
 *
 * @module components/Counter-ui
 * @see module:view-model/commonVM
 * @author Catalin Nita <catalin.nita@immediate.co.uk>
 * @version 1.0
 */

export default (function Counter() {
  // disable card when is expired so nobody could click on anything
  const disableCard = (counters) => {
    counters.forEach((obj) => {
      const parentObj = obj.closest('.standard-card-new');
      const links = Array.from(parentObj.querySelectorAll('a'));
      const button = parentObj.querySelector('.standard-button');
      const buttonTxt = button.querySelector('.standard-button__inner');
      const expiredMessage = obj.getAttribute('expiredmessage');
      const additionalInfo = obj.closest('.standard-card-new__additional-info');
      // remove all links
      links.forEach((link) => {
        link.removeAttribute('href');
      });
      // disable button
      additionalInfo.style.display = 'none';
      button.classList.add('standard-button--disabled');
      buttonTxt.innerHTML = expiredMessage;
    });
    return true;
  };

  // update time on each second
  const updateTime = (secondsLeft, dateSeconds, timestamp) => {
    let hours = 0;
    let hourstxt = '';
    let minutes = 0;
    let minutestxt = '';
    let seconds = 0;
    let secondstxt = '';
    const secondsLeftT = secondsLeft;
    const sel = `time[dateseconds="${timestamp}"]`;
    const counters = Array.from(document.querySelectorAll(sel));
    // if timestamp is 0 then disable the card
    if (secondsLeftT[timestamp] <= 0) {
      clearInterval(window[`counter${timestamp}`]);
      disableCard(counters);
      return secondsLeftT;
    }
    // set the timestamp if none is set
    if (!secondsLeftT[timestamp]) {
      secondsLeftT[timestamp] = dateSeconds;
    }
    // calculate and insert the formated time
    hours = Math.floor(secondsLeftT[timestamp] / 3600);
    minutes = Math.floor(((secondsLeftT[timestamp]) - (hours * 3600)) / 60);
    seconds = Math.floor(((secondsLeftT[timestamp]) - (hours * 3600) - (minutes * 60)));
    // -- show hours just if it's greater than 0
    if (hours > 0) {
      hourstxt = `${hours}h `;
    }
    // -- show minutes just if it's greater than 0
    if (minutes > 0 || hours > 0) {
      minutestxt = `${minutes}m `;
    }
    secondstxt = `${seconds}s`;
    secondsLeftT[timestamp] -= 1;

    counters.forEach((obj) => {
      const element = obj;
      element.innerHTML = `${hourstxt}${minutestxt}${secondstxt}`;
    });

    return secondsLeftT;
  };

  // set the intervals for time update
  const init = () => {
    let secondsLeft = {};
    const counters = Array.from(document.querySelectorAll('.js-counter'));
    const dateNow = Math.floor(Date.now() / 1000);
    counters.forEach((obj) => {
      // expiration date timestamp
      const timestamp = Math.floor(obj.getAttribute('dateseconds'));
      // difference in secons between UTC timezone and timezone set in admin
      const timeDiff = Math.floor(obj.getAttribute('timediff'));
      // add the timezone difference to UTC timezone to get real timestamp
      const dateNowDiff = dateNow + timeDiff;
      // how many seconds are left for a given offer
      const dateSeconds = timestamp - dateNowDiff;
      if (!window[`counter${timestamp}`]) {
        window[`counter${timestamp}`] = setInterval(() => {
          secondsLeft = updateTime(secondsLeft, dateSeconds, timestamp);
        }, 1000);
      }
    });
  };
  return {
    init,
  };
}());
