import { createUIMap } from 'js/utils';

/**
 * Handles clicking of the password input toggle.
 *
 * The toggle shows/hides the content of an associated input field.
 * @module components/Password-Toggle-ui
 * @see module:view-model/commonVM
 * @version 1.0
 */

export default {
  uimap: createUIMap({
    container: '.js-input-password',
    buttonInnerContainer: '.standard-button__inner',
  }),

  /**
   * Get the toggle button from inside a container element.
   * @param {HTMLElement} containerEl
   *   A container element.
   */
  getToggleButton(containerEl) {
    const tagName = 'button';
    const elements = containerEl.getElementsByTagName(tagName);
    return elements[0];
  },

  /**
   * Get an input field from inside a container element.
   * @param {HTMLElement} containerEl
   *   A container element.
   */
  getInputField(containerEl) {
    const tagName = 'input';
    const elements = containerEl.getElementsByTagName(tagName);
    return elements[0];
  },

  /**
   * Get the container ancestor for a toggle button.
   * @param {HTMLElement} el
   *   A toggle button element.
   */
  getContainerAncestor(el) {
    const parentEl = el.parentElement;
    const className = this.uimap.container.replace(/^\./, '');
    const parentIsContainer = parentEl.classList.contains(className);
    if (!parentIsContainer) {
      return this.getContainerAncestor(el.parentElement);
    }
    return el.parentElement;
  },

  /**
   * Set an input field to type text and update the password button text.
   * @param {HTMLElement} toggleButtonEl
   *   A toggle button element.
   * @param {HTMLElement} inputFieldEl
   *   An input field element.
   */
  show(toggleButtonEl, inputFieldEl) {
    // On some browsers the inner element will not recieve the click
    const btnInnerEl = toggleButtonEl.querySelector(this.uimap.buttonInnerContainer);
    const textEl = btnInnerEl || toggleButtonEl;
    textEl.textContent = 'Hide';
    inputFieldEl.setAttribute('type', 'text');
    inputFieldEl.focus();
  },

  /**
   * Set an input field to type password and update the password button text.
   * @param {HTMLElement} toggleButtonEl
   *   A toggle button element.
   * @param {HTMLElement} inputFieldEl
   *   An input field element.
   */
  hide(toggleButtonEl, inputFieldEl) {
    // On some browsers the inner element will not recieve the click
    const btnInnerEl = toggleButtonEl.querySelector(this.uimap.buttonInnerContainer);
    const textEl = btnInnerEl || toggleButtonEl;
    textEl.textContent = 'Show';
    inputFieldEl.setAttribute('type', 'password');
    inputFieldEl.focus();
  },

  /**
   * Toggle password input between showing asterixes and text.
   * @param {Object} evt
   *   An event called on a password toggle button.
   */
  toggle(evt) {
    const toggleButtonEl = evt.target;
    const containerEl = this.getContainerAncestor(toggleButtonEl);
    const inputFieldEl = this.getInputField(containerEl);
    const type = inputFieldEl.getAttribute('type');
    if (type === 'password') {
      this.show(toggleButtonEl, inputFieldEl);
    } else if (type === 'text') {
      this.hide(toggleButtonEl, inputFieldEl);
    }
  },

  /**
   * Toggle when space or enter pressed.
   * @param {Object} evt
   *   An event called on a password toggle button.
   */
  buttonKeyDown(evt) {
    const spaceKey = 32;
    const enterKey = 13;
    if (evt.keyCode === spaceKey || evt.keyCode === enterKey) {
      this.toggle(evt);
    }
  },

  /**
   * Add event listeners to the password toggle button.
   *
   * Will want to revisit this at some point; it could be more generic and
   * uses weird methods to get back up to the container and down to the input,
   * partly as the button currently contains a bunch of nested divs and
   * apprently we can't be sure which will recieve the click event.
   * @param {HTMLElement} containerEl
   *   The password toggle container element.
   */
  addEventListeners(containerEl) {
    const toggleButtonEl = this.getToggleButton(containerEl);
    const inputFieldEl = this.getInputField(containerEl);
    const clickHandler = this.toggle.bind(this);
    const keyDownHandler = this.buttonKeyDown.bind(this);

    if (!toggleButtonEl || !inputFieldEl) {
      return;
    }

    inputFieldEl.setAttribute('type', 'password');

    toggleButtonEl.addEventListener('click', clickHandler);
    toggleButtonEl.addEventListener('keydown', keyDownHandler);

    // Remove event listeners prior to leaving the page.
    window.addEventListener('beforeunload', () => {
      toggleButtonEl.removeEventListener('click', clickHandler);
      toggleButtonEl.removeEventListener('keydown', keyDownHandler);
    });
  },

  /**
   * Initialise.
   */
  init() {
    this.uimap.$$container.forEach((el) => {
      this.addEventListeners(el);
    });
  },
};
