/**
 * A temporary component to adjust the beta-banner.twig link returning a user to
 * the live site after an Optimizly redirect for beta A/B testing.
 *
 * This component is very limited to the initial beta requirement. It is unlikely
 * to be any use after that for regular A/B testing.
 *
 * @module components/beta-banner-ui
 * @see module:view-model/commonVM
 * @author Steve gibbings <steve.gibbings@immediate.co.uk>
 * @version 1.0
 */

export default (function NavigationUI() {
  return {
    /**
     * Initialises the component: replaces the return site link href
     * with httpReferrer if there is one and it wasn't from the beta site
     *
     * This stops the link being changed when the referrer was from
     * the beta site already. In that case they just get sent back to
     * the home page of the live site.
     *
     * @function
     */
    init: function init() {
      const httpReferrer = document.referrer;
      const oldsiteLink = document.querySelector('.js-beta-banner-link');
      if (oldsiteLink && !oldsiteLink.href.includes('beta.')
        && httpReferrer && httpReferrer !== '') {
        oldsiteLink.href = httpReferrer;
      }
    },
  };
}());
