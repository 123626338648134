/**
 * Class to handle the programmatic collapsing of an element.
 *
 * It essentially toggles the aria-expanded and aria-hidden attributes; it is
 * then up to individual components to style accordingly.
 */
class Collapser {
  /**
   * Initialise.
   * @param {HTMLElement} collapsibleElement
   *   An element to show or hide.
   * @param {HTMLElement} toggleElement
   *   An element to indicate the state of the collapsible element.
   */
  constructor(collapsibleElement, toggleElement) {
    this.collapsibleElement = collapsibleElement;
    this.toggleElement = toggleElement;
  }

  /**
   * Collapse an element.
   *
   * The element is expanded on el.collapse('show'), hidden on el.collapse('hide')
   * and toggled on el.collapse('toggle').
   * @param {string} state
   *   The collapsed state.
   */
  collapse(state) {
    if (state === 'toggle') {
      this.toggle();
    } else if (state === 'show') {
      this.show();
    } else if (state === 'hide') {
      this.hide();
    }
  }

  /**
   * Show the collapsible element.
   */
  show() {
    this.toggleElement.setAttribute('aria-expanded', true);
    this.collapsibleElement.setAttribute('aria-hidden', false);
  }

  /**
   * Hide the collapsible element.
   */
  hide() {
    this.toggleElement.setAttribute('aria-expanded', false);
    this.collapsibleElement.setAttribute('aria-hidden', true);
  }

  /**
   * Toggle the state of the collapsible element.
   * @param {Object} event
   *   An event.
   */
  toggle() {
    const isExpanded = this.collapsibleElement.getAttribute('aria-hidden') === 'false';
    if (isExpanded) {
      this.hide();
    } else {
      this.show();
    }
  }
}

/**
 * Make an element collapsible.
 *
 * All of the functions from the Collapser class are added to the HTML element.
 * If the toggle element has an aria-controls attribute that points to the
 * collapsible element then a click handler will be added. This is so that we
 * can prevent the default click to expand behaviour but still have access to
 * the programmatic API. Might need a rethink at some point; it perhaps adds a
 * bit too much complexity to the components.
 * @param {HTMLElement} containerElement
 *   A container element to add the collapse functions to.
 * @param {HTMLElement} collapsibleElement
 *   An element to show/hide.
 * @param {HTMLElement} toggleElement
 *   An element to control the showing or hiding.
 */
export default (containerElement, collapsibleElement, toggleElement) => {
  const collapser = new Collapser(collapsibleElement, toggleElement);
  const propertyNames = Object.getOwnPropertyNames(Collapser.prototype);
  const toggleControls = toggleElement.getAttribute('aria-controls');

  // Add all functions of Collapser to the container element
  propertyNames.filter(name => name !== 'constructor').forEach((name) => {
    // eslint-disable-next-line no-param-reassign
    containerElement[name] = collapser[name].bind(collapser);
  });

  // Add click handler to the toggle element if it has an aria-controls attribute
  // that points to the collapsible element
  if (document.getElementById(toggleControls) === collapsibleElement) {
    toggleElement.addEventListener('click', containerElement.toggle);
    window.addEventListener('beforeunload', () => {
      toggleElement.removeEventListener('click', containerElement.toggle);
    });
  }
};
