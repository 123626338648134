/**
 * A UI component
 *
 * @module components/searchbox-ui
 * @see module:view-model/commonVM
 * @author Daniel Harte <daniel.harte@immediate.co.uk>
 * @version 1.0
 */

import FormUI from 'js/components/Form-ui.js';
import TextboxClearableUI from 'js/components/Textbox-clearable-ui.js';

export default (function searchboxUI() {
  const defaults = {
    selectors: {
      form: '.js-form-searchbox',
    },
  };

  let settings;

  const init = function init(options) {
    settings = Object.assign(defaults, options);

    // eslint-disable-next-line no-unused-vars
    const form = new FormUI({
      form: settings.selectors.form,
    });

    // eslint-disable-next-line no-unused-vars
    const clearableInput = new TextboxClearableUI();
    clearableInput.change();
  };
  return {
    init,
  };
}());
