/**
 * Generic form module. Responsible for populating form with values and performing client
 * side validation. An 'onSubmit' function can also provided to handle custom form submission.
 *
 * This module can be instantiated through 'new FormUI(options)'
 * See sampleOptions below for example options object.
 *
 * @module components/FormUI
 * @author Rohan Milton <rohan.milton@immediate.co.uk>
 * @version 1.0
 */

// example of settings object that may be passed into constructor
// eslint-disable-next-line no-unused-vars
const sampleOptions = {
  form: '.js-form', // form element node or selector
  inputs: {
    'old-password': { // name attribute of input
      defaultValue: '', // default value of input
      checks: { // contraints to check input against
        required: false, // whether input is mandatory
        max: null, // max value for number input
        min: null, // min value for number input
        maxLength: null, // max length of input value
        minLength: null, // min length of input value
        step: 1, // legal intervals for number input
        pattern: '?([0-9])', // regex pattern that input is checked against
        type: 'email', // html5 input type, example 'tel', 'email', 'date'

        /**
        * Custom validation function. Passes input value as argument.
        * Return an error message string if validation fails, empty string if validation passes, or
        * a promise passing the string as argument into its resolve function.
        */
        custom: () => new Promise(resolve => resolve()),
      },
      // error messages displayed in case of HTML5 validation failures
      messages: {
        badInput: '', // user has provided value that the browser is unable to convert.
        rangeOverFlow: '', // value is greater than the maximum specified by the max attribute
        rangeUnderFlow: '', // value is less than the minimum specified by the min attribute.
        stepMismatch: '', // value is not divisible by step attribute.
        tooLong: '', // value exceeds the specified maxlength for input
        tooShort: '', // value fails to meet the specified minlength for input
        typeMismatch: '', // value is not in the required syntax when type is 'email' or 'url'
        valueMissing: '', // element has a required attribute, but no value.
      },
    },
  },
  css: {
    invalidInput: 'is-invalid', // assigned to input when it fails at least one check
    submittingForm: 'form--submitting', // assigned to form while it is submitting
    buttonDisabled: 'standard-button--disabled', // assigned to button while form is submitting
  },

  /**
  * If true, a disabled css class will be assigned to submit button unless all form inputs
  * pass their assigned checks.
  */
  disableSubmitUntilValid: false,

  /**
  * Optional function to run after form validation has finished, only runs if it is defined.
  * It runs once after all validation fields have been validated.
  */
  afterValidationFail: () => {},

  /**
  * Optional function to be fired when a valid form is submitted. Passes FormData object as
  * its only argument. If not provided, default HTML5 form submission will be used.
  */
  onSubmit: () => {},
};

export default (function Form() {
  // see sampleOptions above for full example of options object
  const defaults = {
    form: '.js-form',
    inputs: {},
    disableSubmitUntilValid: false,
    css: {
      invalidInput: 'is-invalid',
      submittingForm: 'form--submitting',
      buttonDisabled: 'standard-button--disabled',
    },
  };

  // nested element selectors
  const childSelectors = {
    formGroup: '.js-form-control', // input containers
    saveButton: '.js-form-save', // form submit button
  };

  // Constructor. Sets up element references, assigns constraints and populates inputs.
  const FormUI = function FormUI(opts) {
    this.settings = Object.assign({}, defaults, opts);
    // grab reference to form
    if (typeof this.settings.form === 'string') {
      this.settings.form = document.querySelector(this.settings.form);
    }
    if (!this.settings.form) { return; }

    this.settings.saveButton = this.settings.form.querySelector(childSelectors.saveButton);
    if (!this.settings.saveButton) { return; }

    // bind event callbacks
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.refreshSubmitButtonStatus = this.refreshSubmitButtonStatus.bind(this);

    // get all form groups
    const formGroups = Array.from(this.settings.form.querySelectorAll(childSelectors.formGroup));
    formGroups.forEach((formGroup) => {
      // get all inputs in form group
      const inputs = Array.from(formGroup.querySelectorAll('textarea, select, input'));
      inputs.forEach((input) => {
        const inputName = input.getAttribute('name');
        if (inputName) {
          // if input is not in settings, create empty settings object for input
          if (!this.settings.inputs[inputName]) {
            this.settings.inputs[inputName] = {};
          }
          const { defaultValue } = this.settings.inputs[inputName];
          this.assignErrorContainer(inputName, formGroup);
          this.assignInputChecks(inputName);
          this.populateInput(inputName, defaultValue);
          this.settings.inputs[inputName].errorNodes = [];
          // event handler to handle user interacting with an input
          input.addEventListener(this.getValidationEventName(inputName), this.handleChange);
          // event handler to show error messages on input blur
          input.addEventListener('blur', this.handleBlur);
        }
      });
    });
    this.settings.form.addEventListener('submit', this.handleSubmit);
    this.refreshSubmitButtonStatus();
  };

  /**
  * Checks if setting disableSubmitUntilValid is set to true and if so, formats the
  * submit button according to the validity of the Form.
  */
  FormUI.prototype.refreshSubmitButtonStatus = function refreshSubmitButtonStatus() {
    if (this.settings.disableSubmitUntilValid) {
      this.setButtonEnabledStatus(this.isFormValid());
    }
  };

  // handles submit of form
  FormUI.prototype.handleSubmit = function handleSubmit(e) {
    const { onSubmit, form, isSubmitting } = this.settings;
    e.preventDefault();
    // if form is currently submitting, return
    if (isSubmitting) { return; }
    // perform custom validation on all inputs
    this.customValidateAllInputs().then(() => {
      // check if form is valid
      const isValid = this.isFormValid(true);
      if (isValid) {
        this.setSubmitting(true);
        // if custom submit function is provided
        if (onSubmit) {
          const formData = new FormData(form);
          // call custom submit function
          onSubmit(formData);
        } else {
          // submit using default HTML form submit
          form.removeEventListener('submit', this.handleSubmit);
          form.submit();
        }
      }
    }).catch(() => {});
  };

  // handles a change in input's value.
  FormUI.prototype.handleChange = function handleChange(e) {
    const inputName = e.target.getAttribute('name');
    // perform custom validation for input
    this.customValidateInput(inputName).then(() => {
      // refresh button status
      this.refreshSubmitButtonStatus();

      if (this.isInputValid(inputName)) {
        this.removeErrors(inputName);
      }
    });
  };

  // handles a blur of an input
  FormUI.prototype.handleBlur = function handleBlur(e) {
    const inputName = e.target.getAttribute('name');
    this.reportErrorsForInput(inputName);
  };

  // returns an array of all inputs that have a name attribute matching a given string
  FormUI.prototype.getInputs = function getInputs(inputName) {
    if (!this.settings.inputs[inputName]) { return []; }
    if (!this.settings.inputs[inputName].node) {
      const nodeList = this.settings.form.querySelectorAll(`[name='${inputName}']`);
      this.settings.inputs[inputName].node = Array.from(nodeList);
    }
    return this.settings.inputs[inputName].node;
  };

  // Appends new error message to given input's error container
  FormUI.prototype.attachErrorMessage = function attachErrorMessage(inputName, text, classes) {
    const { errorNodes, errorContainer } = this.settings.inputs[inputName];
    // create new error message span
    const errorNode = document.createElement('span');
    const errorIdAttr = `${inputName}-error-${errorNodes.length}`;
    errorNode.setAttribute('id', errorIdAttr);
    errorNode.textContent = text;
    // add css classes to error node
    ['form-helper', 'form-helper--error'].forEach(css => errorNode.classList.add(css));
    errorNode.classList.add(classes);
    errorContainer.appendChild(errorNode);
    this.settings.inputs[inputName].errorNodes.push(errorNode);
  };

  // Removes all error message nodes from a given input's error container.
  FormUI.prototype.removeErrors = function removeErrors(inputName) {
    const { errorNodes } = this.settings.inputs[inputName];
    const input = this.getInputs(inputName)[0];
    if (!input) { return; }
    // remove error nodes
    errorNodes.forEach(errorNode => errorNode.parentNode.removeChild(errorNode));
    this.settings.inputs[inputName].errorNodes = [];
  };

  // Returns true if input is valid, false if not
  FormUI.prototype.isInputValid = function isInputValid(inputName) {
    const input = this.getInputs(inputName)[0];
    // get validity from HTML5 validity object
    return input ? input.validity.valid : true;
  };

  // Removes CSS class and aria attributes from input if it's valid, adds them if it's invalid
  FormUI.prototype.formatInput = function formatInput(inputName) {
    const input = this.getInputs(inputName)[0];
    if (!input) { return; }
    const { errorNodes } = this.settings.inputs[inputName];
    let ariaInvalidAttr;

    // concatenate ID attributes of error messages
    const ariaDescribedByAttr = errorNodes.reduce((attributeVal, errorNode) => {
      const id = errorNode.getAttribute('id');
      return `${attributeVal} ${id}`;
    }, '');

    if (this.isInputValid(inputName)) {
      ariaInvalidAttr = 'false';
      input.classList.remove(this.settings.css.invalidInput);
    } else {
      ariaInvalidAttr = 'true';
      input.classList.add(this.settings.css.invalidInput);
    }

    // add aria attributes to input
    input.setAttribute('aria-invalid', ariaInvalidAttr);
    input.setAttribute('aria-describedBy', ariaDescribedByAttr);
  };

  // Checks to see if an input is invalid and if so, attaches error messages to it.
  FormUI.prototype.reportErrorsForInput = function reportErrorsForInput(inputName) {
    const input = this.getInputs(inputName)[0];
    if (!input) { return; }
    // get native HTML5 validity state object
    const { validity } = input;
    // get default error messages from settings object
    const { messages } = this.settings.inputs[inputName];

    // clear current error messages
    this.removeErrors(inputName);
    // A 'for loop' is used below since Object.keys() doesn't recognise an object's prototype props
    // eslint-disable-next-line no-restricted-syntax
    for (const key in validity) {
      if (key !== 'valid') {
        // Each property of the HTML5 validity object is true if that particular check has failed
        const isErrored = validity[key];
        if (isErrored) {
          /*
          * Get the relevant error message. If custom error, get error message stored in HTML5
          * property validityMessage. Otherwise fetch it from settings object
          */
          const errorMsg = (key === 'customError') ? input.validationMessage : messages[key];
          this.attachErrorMessage(inputName, errorMsg, messages.classes);
        }
      }
    }

    this.formatInput(inputName);
  };

  /**
  * Checks if a given input has a custom validation function assigned and if so, executes it.
  * Returns a promise that resolves when validation is complete, passing an error message string
  * if validation failed.
  */
  FormUI.prototype.customValidateInput = function customValidateInput(inputName) {
    const input = this.getInputs(inputName)[0];
    const { checks } = this.settings.inputs[inputName];

    // if input has no custom validation function assigned to it in settings object
    if (!checks || !checks.custom || !input.value) {
      if (input) {
        // reset custom validity status
        input.setCustomValidity('');
      }
      // return resolved promise
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      // execute custom validation function
      const result = checks.custom(input.value);
      // result can be promise or string, so passing it into Promise.resolve() to handle both
      Promise.resolve(result).then((customValidationErrorMsg) => {
        // if error message is found
        input.setCustomValidity(customValidationErrorMsg);
        resolve();
      });
    });
  };

  // Returns true if a given input is either a radio button or checkbox, otherwise returns false.
  FormUI.prototype.isInputToggable = function isInputToggable(inputName) {
    const input = this.getInputs(inputName)[0];
    if (input) {
      const inputType = input.getAttribute('type');
      return (inputType === 'checkbox' || inputType === 'radio');
    }
    return false;
  };

  // Populates a given input with a value.
  FormUI.prototype.populateInput = function populateInput(inputName, newValue) {
    const inputs = this.getInputs(inputName);
    if (newValue === undefined || !inputs.length) { return; }
    inputs.forEach((inputNode) => {
      const input = inputNode;
      // If radio button or checkbox
      if (this.isInputToggable(inputName)) {
        // If input's value matches the newValue, 'check' the input. Otherwise, 'uncheck' the input
        if (newValue instanceof Array) {
          if (newValue.includes(input.value)) {
            input.checked = true;
          }
        } else if (newValue.includes(',')) {
          // value must be csv, check the input that has a value in the csv string
          input.checked = newValue.includes(input.value);
        } else {
          input.checked = (input.value === newValue.toString());
        }
      } else {
        input.value = newValue;
      }
    });
    this.customValidateInput(inputName);
  };

  /*
  * Populates multiple inputs with given values
  *
  * @param {Object} Object with input names as keys and corresponding values as properties.
  */
  FormUI.prototype.populateInputs = function populateInputs(inputMap) {
    if (inputMap) {
      Object.keys(inputMap).forEach((inputName) => {
        this.populateInput(inputName, inputMap[inputName]);
      });
    }
  };

  // Returns appropriate event at which validation should occur for a given input
  FormUI.prototype.getValidationEventName = function getValidationEventName(inputName) {
    return this.isInputToggable(inputName) ? 'change' : 'keyup';
  };

  // Looks up constraints in settings object and assigns them to the input
  FormUI.prototype.assignInputChecks = function assignInputChecks(inputName) {
    const inputs = this.getInputs(inputName);
    const { checks } = this.settings.inputs[inputName];
    if (!checks) { return; }
    inputs.forEach((inputNode) => {
      const input = inputNode;
      Object.keys(checks).forEach((key) => {
        if (key !== 'custom') {
          // assign constraint to input
          input[key] = checks[key];
        }
      });
    });
  };

  /**
  * Assigns an appropriate error message container a given input.
  * If an overriding error container is stored against the input in the settings object,
  * it will be used. Otherwise, the default error container will be used.
  */
  FormUI.prototype.assignErrorContainer = function assignErrorContainer(inputName, defContainer) {
    const { errorContainer, customErrorContainer } = this.settings.inputs[inputName];
    const { form } = this.settings;
    // check for overriding error container in settings object
    if (errorContainer && typeof errorContainer === 'string') {
      this.settings.inputs[inputName].errorContainer = form.querySelector(errorContainer);
    } else if (!customErrorContainer) {
      // no overriding error container exists, use default provided
      // unless the customErrorContainer flag exists and an error container
      // has already been set. The additional flag is required as otherwise any
      // custom error container passed in the settings will essentially be
      // ignored for radio buttons with the same name attribute
      this.settings.inputs[inputName].errorContainer = defContainer;
    }
  };

  /**
  * Returns true if all inputs in form are valid
  *
  * @param {Boolean} true to report validation errors to user
  */
  FormUI.prototype.isFormValid = function isFormValid(reportErrors = false) {
    const { afterValidationFail } = this.settings;
    let formValid = true;
    Object.keys(this.settings.inputs).forEach((inputName) => {
      const inputValid = this.isInputValid(inputName);
      if (!inputValid) {
        // Scroll to the first invalid input
        if (formValid) {
          this.scrollToInput(inputName);
        }

        formValid = inputValid;
        if (reportErrors) {
          this.reportErrorsForInput(inputName);
        }
      }
    });
    if (!formValid) {
      // check if 'afterValidationFail' is defined, if it is we run it
      if (typeof afterValidationFail === 'function') {
        afterValidationFail();
      }
    }
    return formValid;
  };

  /*
  * Performs custom validations for all inputs. Returns single promise that resolves when
  * all validations are complete. To check if form is valid, see method isFormValid().
  */
  FormUI.prototype.customValidateAllInputs = function customValidateAllInputs() {
    // call validate function for each input, adding returned promise to an array
    const promises = Object.keys(this.settings.inputs).map(name => this.customValidateInput(name));
    // resolve a single promise when all promises are resolved
    return Promise.all(promises);
  };

  // sets the submitting status of the form and adds/removes CSS class to form element
  FormUI.prototype.setSubmitting = function setSubmitting(isSubmitting) {
    if (isSubmitting) {
      this.settings.form.classList.add(this.settings.css.submittingForm);
    } else {
      this.settings.form.classList.remove(this.settings.css.submittingForm);
    }
    this.setButtonEnabledStatus(!isSubmitting);
    this.settings.isSubmitting = isSubmitting;
  };

  // adds or removes disabled css class to the submit button
  FormUI.prototype.setButtonEnabledStatus = function setButtonEnabledStatus(isEnabled) {
    if (isEnabled) {
      this.settings.saveButton.classList.remove(this.settings.css.buttonDisabled);
    } else {
      this.settings.saveButton.classList.add(this.settings.css.buttonDisabled);
    }
  };

  /*
  * Show external errors inline against inputs
  *
  * @param {Object} Object with input names as keys and corresponding error messages as values.
  */
  FormUI.prototype.addServerErrors = function showFail(inputErrors) {
    if (inputErrors) {
      Object.keys(inputErrors).forEach((inputName) => {
        if (this.settings.inputs[inputName]) {
          const input = this.getInputs(inputName)[0];
          input.setCustomValidity(inputErrors[inputName]);
          this.reportErrorsForInput(inputName);
        }
      });
    }
  };

  // Destroys event handlers created by this module
  FormUI.prototype.destroy = function destroy() {
    Object.keys(this.settings.inputs).forEach((inputName) => {
      const inputs = this.getInputs(inputName);
      inputs.forEach((input) => {
        input.removeEventListener(this.getValidationEventName(inputName), this.handleChange);
        input.removeEventListener('blur', this.handleBlur);
      });
    });
    this.settings.form.removeEventListener('submit', this.handleSubmit);
  };

  /**
   * Scroll to an input element.
   */
  FormUI.prototype.scrollToInput = function scrollToInput(inputName) {
    const el = document.querySelector(`input[name="${inputName}"]`);
    el.scrollIntoView({
      behavior: 'smooth',
    });
  };

  FormUI.prototype.scrollToElement = function scrollToElement(element) {
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return FormUI;
}());
