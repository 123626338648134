(() => {
  let lastTime = 0;
  const vendors = ['webkit', 'moz'];
  for (let x = 0; x < vendors.length && !window.requestAnimationFrame; x += 1) {
    const winVendor = vendors[x];
    window.requestAnimationFrame = window[`${winVendor}RequestAnimationFrame`];
    window.cancelAnimationFrame =
    window[`${vendors[x]}CancelAnimationFrame`] || window[`${vendors[x]}CancelRequestAnimationFrame`];
  }
  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = (callback) => {
      const currTime = new Date().getTime();
      const timeToCall = Math.max(0, 16 - (currTime - lastTime));
      const id = window.setTimeout(() => {
        callback(currTime + timeToCall);
      }, timeToCall);
      lastTime = currTime + timeToCall;
      return id;
    };
  }
  if (!window.cancelAnimationFrame) {
    window.cancelAnimationFrame = (id) => {
      clearTimeout(id);
    };
  }
})();
export default (function Carousel() {
  let settings;
  let currentSlide = 0;
  let interval;
  let autoSetup = false;
  /**
   * Set the defaults
   * @type {Object}
   */
  const defaults = {
    auto: true,
    delay: 4000,
    elements: {
      container: document.querySelector('.js-carousel__slides-container'),
      slides: document.querySelectorAll('.js-carousel-slide'),
      tabs: document.querySelectorAll('.js-carousel-cta-button'),
      accordion: document.querySelectorAll('.js-carousel__slide-title'),
    },
    classes: {
      activeTab: 'cta-button--active',
      activeSlide: 'carousel__slide--active',
      activeSlideTitle: 'carousel__slide-title--active',
      activeUpIndicatorClass: 'cta-button--active-indicator-up',
      activeDownIndicatorClass: 'cta-button--active-indicator-down',
    },
    dataAttr: {
      index: 'data-index',
    },
    desktopWidth: 768,
  };
  /**
   * Check to see if transitionend is available
   * @return {string} Returns string with browser prefix or false
   */
  const whichTransitionEvent = () => {
    const el = document.createElement('fakeelement');
    const transitions = {
      transition: 'transitionend',
      OTransition: 'oTransitionEnd',
      MozTransition: 'transitionend',
      WebkitTransition: 'webkitTransitionEnd',
    };
    const transitionAvailable = Object.keys(transitions).find((key) => {
      let t;
      if (el.style[key] !== undefined) {
        t = key;
      }
      return t;
    });
    return transitions[transitionAvailable] || false;
  };
  /**
   * Remove multiple classes using array
   * @param  {Element} elem    The element to remove classes from
   * @param  {array} classes The class names to be removed
   */
  const removeMultipleClasses = (elem, classes) => {
    Array.from(classes).forEach((className) => {
      elem.classList.remove(className);
    });
  };
  /**
   * Add multiple classes using array
   * @param {[type]} elem    [description]
   * @param {[type]} classes [description]
   */
  const addMultipleClasses = (elem, classes) => {
    Array.from(classes).forEach((className) => {
      elem.classList.add(className);
    });
  };
  /**
   * Fade out element
   * @param  {[type]} el [description]
   * @return {[type]}    [description]
   */
  const fadeOut = (el) => {
    const elem = el;
    return new Promise((resolve) => {
      addMultipleClasses(elem, ['animate', 'fade-out']);
      const fadeCallback = (e) => {
        elem.style.display = 'none';
        removeMultipleClasses(elem, ['animate', 'fade-out']);
        resolve();
        e.target.removeEventListener(e.type, fadeCallback);
      };
      if (!settings.transition) {
        elem.style.display = 'none';
        removeMultipleClasses(elem, ['animate', 'fade-out']);
      } else {
        elem.addEventListener(settings.transition, fadeCallback, false);
      }
    });
  };
  /**
   * Fade Out an element
   * @param  {[type]} el      [description]
   * @param  {[type]} display [description]
   * @return {[type]}         [description]
   */
  const fadeIn = (el, display) => {
    const elem = el;
    return new Promise((resolve) => {
      const fadeInCallback = (e) => {
        removeMultipleClasses(elem, ['animate', 'fade-in', 'fade-out']);
        e.target.removeEventListener(e.type, fadeInCallback);
        resolve();
      };
      elem.addEventListener('transitionend', fadeInCallback, false);
      elem.classList.add('fade-out');
      elem.style.display = display || 'block';
      elem.classList.add('animate');
      setTimeout(() => {
        elem.classList.add('fade-in');
      }, 0);
    });
  };
  /**
   * Check for mobile devices
   * @return {Boolean}
   */
  const viewPort = () => {
    let e = window;
    let a = 'inner';
    if (!('innerWidth' in window)) {
      a = 'client';
      e = document.documentElement || document.body;
    }
    return e[`${a}Width`];
  };
  /**
   * Stop functions from firing constantly
   * @param  {[type]} func      [description]
   * @param  {[type]} wait      [description]
   * @param  {[type]} immediate [description]
   * @param  {[type]} args      [description]
   * @return {[type]}           [description]
   */
  const debounce = (func, wait, immediate, ...args) => {
    let timeout;
    return () => {
      const context = this;
      const later = () => {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  };
  /**
   * Clear the active classes from tabs
   */
  const clearClasses = (elements) => {
    const mobileClasses = [
      settings.classes.activeTab,
      settings.classes.activeDownIndicatorClass,
      settings.classes.activeSlideTitle,
    ];
    const desktopClasses = [
      settings.classes.activeTab,
      settings.classes.activeUpIndicatorClass,
    ];
    const activeTab = (viewPort() < settings.desktopWidth) ? mobileClasses : desktopClasses;
    return new Promise((resolve) => {
      Array.from(elements).forEach((elem) => {
        removeMultipleClasses(elem, activeTab);
      });
      resolve();
    });
  };
  const showSlide = (dataIndex, auto) => {
    const tabIndex = parseInt(dataIndex, 10);
    if (!auto) {
      if (tabIndex !== currentSlide) {
        removeMultipleClasses(settings.elements.slides[tabIndex], ['fade-out', 'fade-in']);
        settings.elements.slides[tabIndex].style.display = 'block';
        Array.from(settings.elements.slides).forEach((item, index) => {
          const slide = item;
          if (index < tabIndex) {
            slide.style.display = 'none';
          } else if (index > tabIndex) {
            slide.style.display = 'block';
          }
          removeMultipleClasses(slide, ['fade-in', 'fade-out']);
        });
        currentSlide = tabIndex;
      }
    } else {
      if (tabIndex > currentSlide) {
        fadeOut(settings.elements.slides[currentSlide]);
      } else {
        fadeIn(settings.elements.slides[tabIndex], 'block').then(() => {
          Array.from(settings.elements.slides).forEach((item, index) => {
            const slide = item;
            if (index > tabIndex) {
              slide.style.display = 'block';
            }
          });
        });
      }
      currentSlide = tabIndex;
    }
  };
  /**
   * Set the active tab on load
   */
  const selectFirstTab = () => {
    if (viewPort() < settings.desktopWidth) {
      const classesAccordion = [
        settings.classes.activeTab,
        settings.classes.activeDownIndicatorClass,
        settings.classes.activeSlideTitle,
      ];
      const animateClasses = [
        'fade-in',
        'fade-out',
        'animate',
      ];
      settings.elements.slides[0].classList.add(settings.classes.activeSlide);
      clearClasses(settings.elements.slides, classesAccordion.concat(animateClasses));
      addMultipleClasses(settings.elements.accordion[0], classesAccordion);
      Array.from(settings.elements.slides).forEach((item) => {
        const slide = item;
        slide.style.removeProperty('display');
      });
    } else {
      showSlide(0, false);
      const classesTabs = [settings.classes.activeTab, settings.classes.activeUpIndicatorClass];
      Array.from(settings.elements.slides).forEach((item) => {
        const slide = item;
        slide.classList.remove(settings.classes.activeSlide);
      });
      clearClasses(settings.elements.tabs, classesTabs);
      addMultipleClasses(settings.elements.tabs[0], classesTabs);
    }
  };
  /**
   * Attch the click event to the tabs
   */
  const autoSlide = () => {
    autoSetup = true;
    let currentTab;
    let tabIndex;
    return window.setInterval(() => {
      currentTab = document.querySelector(`.${settings.classes.activeTab}`);
      tabIndex = parseInt(currentTab.getAttribute(settings.dataAttr.index), 10);
      let slideValue = (tabIndex + 1);
      if (tabIndex === (settings.elements.tabs.length - 1)) {
        tabIndex = -1;
        slideValue = 0;
      }
      showSlide(slideValue, true);
      clearClasses(settings.elements.tabs).then(() => {
        addMultipleClasses(settings.elements.tabs[tabIndex + 1], [
          settings.classes.activeTab,
          settings.classes.activeUpIndicatorClass,
        ]);
      });
    }, settings.delay);
  };
  /**
   * Set up auto animations (fade)
   */
  const setUpAuto = () => {
    if (viewPort() >= settings.desktopWidth && settings.auto) {
      interval = autoSlide();
      settings.elements.container.addEventListener('mouseover', () => {
        autoSetup = false;
        window.clearInterval(interval);
      }, false);
      settings.elements.container.addEventListener('mouseout', () => {
        autoSetup = true;
        interval = autoSlide();
      }, false);
      Array.from(settings.elements.tabs).forEach((tab) => {
        tab.addEventListener('mouseover', () => {
          autoSetup = false;
          window.clearInterval(interval);
        }, false);
        tab.addEventListener('mouseout', () => {
          autoSetup = true;
          interval = autoSlide();
        }, false);
      });
    }
  };
  /**
   * Set up the slides z-index values
   */
  const setUpSlides = () => {
    currentSlide = 0;
    const slideLength = settings.elements.slides.length;
    return new Promise((resolve) => {
      Array.from(settings.elements.slides).forEach((item, index) => {
        const slide = item;
        slide.classList.remove(settings.classes.activeSlide);
        if (viewPort() >= settings.desktopWidth) {
          slide.style.zIndex = ((slideLength - 1) - index);
        } else {
          slide.style.removeProperty('z-index');
          if (index === 0) {
            slide.classList.add(settings.classes.activeSlide);
          }
        }
      });
      resolve();
    });
  };
  const resetCarousel = () => {
    setUpSlides().then(() => {
      selectFirstTab();
      if (!autoSetup && settings.elements.tabs.length > 1) {
        setUpAuto();
      }
    });
  };
  /**
   * [attachEvents description]
   * @return {[type]} [description]
   */
  const attachEvents = () => {
    let winWidth = viewPort();
    window.addEventListener('resize', debounce(() => {
      if (winWidth !== viewPort()) {
        resetCarousel();
        winWidth = viewPort();
      }
    }, 500), false);
    Array.from(settings.elements.tabs).forEach((tab) => {
      tab.addEventListener('mouseover', (e) => {
        const elem = e.target;
        clearClasses(settings.elements.tabs, 'desktop').then(() => {
          elem.classList.add(settings.classes.activeTab, settings.classes.activeUpIndicatorClass);
        });
        const tabIndex = elem.getAttribute(settings.dataAttr.index);
        showSlide(tabIndex, false);
      }, false);
    });
    Array.from(settings.elements.accordion).forEach((btn) => {
      const clickEvent = ('ontouchend' in document.documentElement === true) ? 'touchend' : 'click';
      btn.addEventListener(clickEvent, (e) => {
        const elem = e.target;
        const tabIndex = elem.getAttribute(settings.dataAttr.index);
        // const slideElem = elem.nextElementSibling;
        const slideElem = document.querySelector(`[data-slide="${tabIndex}"]`);
        clearClasses(settings.elements.accordion, 'mobile').then(() => {
          elem.classList.add(settings.classes.activeTab, settings.classes.activeDownIndicatorClass);
        });
        Array.from(settings.elements.slides).forEach((slide) => {
          const thisSlide = slide;
          thisSlide.classList.remove(settings.classes.activeSlide);
        });
        slideElem.classList.add(settings.classes.activeSlide);
        elem.classList.add(settings.classes.activeSlideTitle);
        e.preventDefault();
        e.stopPropagation();
      });
    });
  };
  /**
   * Initialise the carousel
   * @param  {Object} options
   * @return         [description]
   */
  const init = (options) => {
    const carouselElements = document.querySelectorAll('.js-carousel');
    if (carouselElements.length) {
      settings = Object.assign(defaults, options);
      settings.transition = whichTransitionEvent();
      attachEvents();
      resetCarousel();
    }
  };
  return {
    init,
  };
}());
