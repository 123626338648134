export default (() => {
  const definePlayers = (players) => {
    players.forEach((player) => {
      const myPlayer = player;

      myPlayer.onloadedmetadata = () => {
        const event = 'videoEvent';
        const videoDuration = myPlayer.duration;
        const videoId = myPlayer.getAttribute('data-video-id');
        const videoProvider = 'brightcove';
        const videoUrl = myPlayer.src;

        let played = false;

        let reached25 = false;
        let reached50 = false;
        let reached75 = false;

        const dataLayerPush = (status, percent) => {
          window.dataLayer.push({
            event,
            videoId,
            videoPercent: percent,
            videoProvider,
            videoStatus: status,
            videoUrl,
          });
        };

        myPlayer.onplay = () => {
          if (played === false) {
            dataLayerPush('start');
            played = true;
          }
        };
        myPlayer.ontimeupdate = () => {
          const currentTime = Math.ceil(myPlayer.currentTime);

          if (!reached25 && currentTime >= (0.25 * videoDuration)) {
            dataLayerPush('progress', 25);
            reached25 = true;
          }
          if (!reached50 && currentTime >= (0.50 * videoDuration)) {
            dataLayerPush('progress', 50);
            reached50 = true;
          }
          if (!reached75 && currentTime >= (0.75 * videoDuration)) {
            dataLayerPush('progress', 75);
            reached75 = true;
          }
        };
        myPlayer.onwaiting = () => {
          dataLayerPush('buffering');
        };
        myPlayer.onended = () => {
          dataLayerPush('complete');
        };
      };
    });
  };

  const init = () => {
    const players = document.querySelectorAll('.vjs-tech');
    if (players.length) {
      definePlayers(players);
    }
  };

  return {
    init,
    definePlayers,
  };
})();
