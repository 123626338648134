export default class CollapseFixed {
  /**
   * Initialise.
   * @param {HTMLElement} collapsibleElement
   *   An element to show or hide.
   * @param {HTMLElement} containerElement
   *   An element to indicate the state of the container element.
   * @param {HTMLElement} toggleElement
   *   An element to indicate the state of the collapsible element.
   */
  constructor(containerElement, collapsibleElement, toggleElement) {
    this.containerElement = containerElement;
    this.collapsibleElement = collapsibleElement;
    this.toggleElement = toggleElement;
  }

  init() {
    this.containerElement.classList.add('js-collapse--fixed-height');
    this.collapsibleElement.setAttribute('aria-hidden', true);
    this.toggleElement.style.display = 'inline';
    this.setCollapsedHeight();
    const toggleControls = this.toggleElement.getAttribute('aria-controls');

    if (document.getElementById(toggleControls) === this.collapsibleElement) {
      this.toggleElement.addEventListener('click', () => {
        this.toggleFixedHeight();
      });
      this.collapsibleElement.addEventListener('transitionend', () => {
        this.transitionEnd();
      });
    }
  }

  toggleFixedHeight() {
    const isCollapsed = this.collapsibleElement.getAttribute('aria-hidden') === 'true';

    if (isCollapsed) {
      this.toggleElement.setAttribute('aria-expanded', true);
      this.collapsibleElement.setAttribute('aria-hidden', false);
      this.setExpandedHeight();
      this.toggleElement.classList.add('showing');
      return;
    }

    this.toggleElement.setAttribute('aria-expanded', false);
    this.collapsibleElement.setAttribute('aria-hidden', true);

    const elementTransition = this.collapsibleElement.style.transition;
    const elementHeight = `${this.collapsibleElement.scrollHeight}px`;
    this.collapsibleElement.style.transition = '';
    this.toggleElement.classList.remove('showing');

    requestAnimationFrame(() => {
      this.collapsibleElement.style.height = elementHeight;
      this.collapsibleElement.style.transition = elementTransition;
      requestAnimationFrame(() => {
        this.setCollapsedHeight();
      });
    });
  }

  setExpandedHeight() {
    this.collapsibleElement.style.height = `${this.collapsibleElement.scrollHeight}px`;
  }

  transitionEnd() {
    const isExpanded = this.collapsibleElement.getAttribute('aria-hidden') === 'false';
    if (isExpanded) {
      this.collapsibleElement.style.height = '';
    }
  }

  setCollapsedHeight() {
    const collapsedHeight = this.containerElement.getAttribute('data-collapsed-height');
    this.collapsibleElement.style.height = `${collapsedHeight}px`;
  }
}
