/**
 * Created by steve.gibbings on 27/06/2016.
 */

export default (() => {
  const defaults = {
    css: {
      selectors: {
        delegateQuery: '.js-social-share-collection',
        socialMediaLinkClass: 'js-social-share-popup',
      },
    },
  };

  let settings;
  let socialMediaDelegate;

  const handleSocialMediaClick = (e) => {
    const element = e.target;

    if (element.classList.contains(settings.css.selectors.socialMediaLinkClass)
      && element.hasAttribute('href')) {
      e.preventDefault();
      window.open(element.getAttribute('href'), 'SocialMediaSharePopUp', 'width=700, height=300');
    }
  };

  const init = (options) => {
    settings = Object.assign(defaults, options);

    const socialMediaLinks = document.getElementsByClassName(
      settings.css.selectors.socialMediaLinkClass,
    );

    socialMediaDelegate = document.querySelectorAll(settings.css.selectors.delegateQuery);

    if (socialMediaDelegate && socialMediaLinks.length > 0) {
      Array.from(socialMediaDelegate).forEach((delegateItem) => {
        delegateItem.addEventListener('click', handleSocialMediaClick);
      });
    }
  };

  return {
    init,
  };
})();
