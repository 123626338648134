/**
 * Validate a hash value against excpected values
 * @param  {string} hash    The original hash
 * @param  {Object} hashMap An object of kev/value pairs of expected values
 * @return {string}         Validated hash
 */
export default (hash, hashMap = {}) => {
  let cleanHashStr = hashMap.default || hash;
  if (hash && hash.length && Object.keys(hashMap).length) {
    const cleanHash = hash.replace(/[^a-z\s]/gi, '').toLowerCase();
    if (hashMap[cleanHash]) {
      cleanHashStr = hashMap[cleanHash];
    }
  }
  return cleanHashStr;
};
