import { buildIconUrl } from '@immediate_media/icons';

/**
 * Render icons from our icons package.
 */
export default (() => {
  /**
   * Append the use element to the SVG.
   */
  const appendUse = (svgEl, name) => {
    const svgns = 'http://www.w3.org/2000/svg';
    const xlinkns = 'http://www.w3.org/1999/xlink';
    const useEl = document.createElementNS(svgns, 'use');
    const iconPath = buildIconUrl(name, __THEME__, __ICONS_PATH__);
    useEl.setAttributeNS(xlinkns, 'href', iconPath);
    svgEl.appendChild(useEl);
  };

  /**
   * Set the icon colour from a design token.
   */
  const setColor = (iconEl) => {
    const { color } = iconEl.dataset;

    if (!color) {
      return;
    }

    // eslint-disable-next-line no-param-reassign
    iconEl.style.color = __DESIGN_TOKENS__.colors[color];
  };

  /**
   * Embed an SVG icon.
   */
  const embedIcon = (iconEl) => {
    const { name } = iconEl.dataset;
    const svgEl = iconEl.querySelector('svg');

    appendUse(svgEl, name);
    setColor(iconEl);
  };

  return {
    init() {
      const iconElements = Array.from(document.querySelectorAll('[data-embed-icon]'));

      iconElements.forEach(iconEl => embedIcon(iconEl));
    },
  };
})();
