/**
 * Debounce keystokes, to limit number of requests when keypress triggers fetch
 * or limit amount of times resize function is fired
 * @module modules/debounce
 * @version 1.0
 */

let timeout;
export default ((cb, wait = 300) => {
  clearTimeout(timeout);
  timeout = setTimeout(cb, wait);
});
