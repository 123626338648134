import { pushMemberStatusEvents } from '@immediate_media/auth-utils';

/**
 * Pushes member status events to the data layer.
 */
export default (() => ({
  init() {
    const memberStatus = document.body.classList.contains('premium-subscriber')
      ? 'Subscriber'
      : 'Member';

    pushMemberStatusEvents({ memberStatus });
  },
}))();
