import Modernizr from 'modernizr';

export default (function PremiumContent() {
  let list = [];
  let scrolling = false;
  let settings = {};

  const defaults = {
    cardSelector: '.standard-card-new--premium-overlay',
    buttonSelector: '.standard-button',
    classActive: 'standard-card-new--premium-overlay-show',
  };

  const closeAllOverlays = (exceptFor) => {
    list.filter(n => n !== exceptFor).forEach((node) => {
      node.classList.remove(settings.classActive);
    });
  };

  const attachTouchEvents = () => {
    scrolling = false;
    document.body.addEventListener('touchend', (e) => {
      if (scrolling) {
        return;
      }

      const card = e.target.closest(settings.cardSelector);
      if (card) {
        e.preventDefault();
        const overlayVisible = card.classList.contains(settings.classActive);
        const tappedButton = e.target.closest(settings.buttonSelector);
        if (overlayVisible && tappedButton && tappedButton.href) {
          window.location = tappedButton.href;
        } else {
          closeAllOverlays(card);
          card.classList.toggle(settings.classActive);
        }
      } else {
        closeAllOverlays();
      }
    });

    document.body.addEventListener('touchmove', () => {
      scrolling = true;
    });

    document.body.addEventListener('touchstart', () => {
      scrolling = false;
    });
  };

  const attachHoverEvents = () => {
    list.forEach((node) => {
      node.addEventListener('mouseenter', () => {
        node.classList.add(settings.classActive);
      });

      node.addEventListener('mouseleave', () => {
        node.classList.remove(settings.classActive);
      });
    });
  };

  // From: https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
  const initPolyfill = () => {
    if (!Element.prototype.matches) {
      Element.prototype.matches = Element.prototype.msMatchesSelector ||
          Element.prototype.webkitMatchesSelector;
    }

    if (!Element.prototype.closest) {
      Element.prototype.closest = function closest(s) {
        let el = this;
        if (!document.documentElement.contains(el)) {
          return null;
        }
        do {
          if (el.matches(s)) return el;
          el = el.parentElement;
        } while (el !== null);
        return null;
      };
    }
  };

  const initialise = (options) => {
    initPolyfill();
    settings = Object.assign({}, defaults, options);
    list = Array.from(document.querySelectorAll(settings.cardSelector));

    if (list.length === 0) {
      return;
    }

    if (Modernizr.touchevents) {
      attachTouchEvents();
    }
    attachHoverEvents();
  };

  return {
    init: initialise,
  };
}());
