/**
 * A UI component
 *
 * @module components/navigation-search-ui
 * @see module:view-model/commonVM
 * @author Eugenio G. Martínez <eugenio.martinez@immediate.co.uk>
 * @author Daniel Harte <daniel.harte@immediate.co.uk>
 * @version 1.0
 */

import TextboxClearableUI from '../components/Textbox-clearable-ui.js';
import debounce from '../modules/debounce';

const defaults = {
  selectors: {
    navSearch: '.nav-search',
    activator: '.nav-search__activator',
    searchForm: '.nav-search__form',
    inputField: '.nav-search__input',
    resultContainer: '.nav-search__results',
  },
  searchApi: {
    limit: 5,
  },
  desktopWidth: 1000, // TODO store these values somewhere central that can be imported?
};

let navSearch;
let activator;
let searchForm;
let inputField;
let resultContainer;
let searchConfigSiteKey;
let searchConfigSiteUrl;
const resultsCache = [];
const searchUrlPath = '/v4/search';

const handleSuccess = (inputValue, json) => {
  resultsCache[inputValue] = json;
  const results = json.data.results.length ? json.data.results : [];
  resultContainer.innerHTML = '';
  let htmlResults = '';
  results.forEach((result) => {
    htmlResults += `
    <li class="grouped-list__sub-list-item">
      <a class="nav-search__link" href="${result.url}">${result.title}</a>
    </li>
    `;
  });
  resultContainer.innerHTML = htmlResults;
};

const changeEventHandler = () => {
  resultContainer.innerHTML = '';
  const inputValue = inputField.value;
  if (inputValue.length >= 3) {
    if (resultsCache[inputValue]) {
      handleSuccess(inputValue, resultsCache[inputValue]);
    } else {
      const fullEndpoint = `${searchConfigSiteUrl}${searchUrlPath}?sitekey=${searchConfigSiteKey}&search=${inputValue}&limit=${defaults.searchApi.limit}&views=core`;

      debounce(() => {
        fetch(fullEndpoint).then((response) => {
          response.json().then((json) => {
            if (response.ok) {
              return json;
            }
            throw response;
          })
          .then(json => handleSuccess(inputValue, json))
          // 404 SyntaxError (on bad JSON) or response.ok is false
          .catch(() => {});
        })
        // 404 TypeError (on endpoint unavailable)
        .catch(() => {});
      });
    }
  }
};

export default (function searchboxUI() {
  const init = () => {
    navSearch = document.querySelector(defaults.selectors.navSearch);
    activator = document.querySelector(defaults.selectors.activator);
    searchForm = document.querySelector(defaults.selectors.searchForm);
    inputField = document.querySelector(defaults.selectors.inputField);
    resultContainer = document.querySelector(defaults.selectors.resultContainer);
    searchConfigSiteUrl = (!!navSearch && navSearch.dataset.searchConfigUrl) || '';
    searchConfigSiteKey = (!!navSearch && navSearch.dataset.searchConfigClientId) || '';

    if (activator && searchForm && inputField) {
      if (window.innerWidth < defaults.desktopWidth) {
        // mobile - activator button submits form
        activator.addEventListener('click', () => {
          searchForm.submit();
        });
      } else {
        // desktop - activator button opens search dropdown
        let expanded = false;
        activator.addEventListener('click', () => {
          navSearch.classList.toggle('is-active');
          activator.setAttribute('aria-expanded', expanded = !expanded);
          inputField.focus();
        });
      }
      // both desktop and mobile - keypress listener (only add if customizer setting is enabled)
      if (navSearch.getAttribute('data-autocomplete-enabled') === 'yes') {
        inputField.addEventListener('input', changeEventHandler, false);
      }
    }
    // eslint-disable-next-line no-unused-vars
    const clearableInput = new TextboxClearableUI();
    clearableInput.change();
  };
  return {
    init,
  };
}());
