/**
 * Handles header and nav sticky behaviours.
 * @module components/headerUI
 * @see module:view-model/commonVM
 * @author James Fidler <james.fidler@immediate.co.uk>
 * @version 1.0
 */
import { isTabletDown } from 'js/utils';

export default (() => {
  const defaults = {
    css: {
      selectors: {
        bannerAdContainer: '.js-ad-banner-container',
        bannerAd: '.js-ad-banner',
        bannerAdWrapper: '.js-ad-banner-wrapper',
        mpuStandard: '.js-mpu',
        nav: '.js-sticky-nav',
        navWrapper: '.js-nav-container',
        header: '.js-site-header',
        headerInner: '.js-site-header-inner',
        headerEyebrow: '.site-header__eyebrow',
        headerNav: '.site-header__nav',
        brandBar: '.site-header__inner > .brand-bar',
        pianoHigh: '.js-piano-high',
      },
    },
  };

  let settings;
  let body;
  let bannerAd;
  let bannerAdRect;
  let bannerAdContainer;
  let bannerAdWrapper;
  let headerEyebrow;
  let nav;
  let navWrapper;
  let navRect;
  let header;
  let headerInner;
  let headerRect;
  let fixNavScrollPos;
  let headerNav;
  let isHeaderSticky = false;       // Should the header stick to the top of the viewport?
  let isNavSticky = false;          // Should the nav be sticking?
  let bannerHeight = 0;
  let pianoHigh;
  let brandBar;

  /**
   * Fixes the given element to the top of the page.
   * @param element {HTMLElement} - The element to be fixed.
   */
  const fixElement = function fixElement(element, extra = 0) {
    if (!element) return;
    const el = element;
    el.style.position = 'fixed';
    el.style.top = `${extra}px`;
  };

  /**
   * This function is responsible for the sticky header behaviour on mobile.
   * Functionality is:
   *  Entire header is sticky, until the bottom of the header touches the top of the first MPU.
   *  The entire header then freezes in place and scrolls up with the page.
   *  This continues until the nav bar touches the top of the window, at which point the nav
   *    bar sticks to the top of the screen.
   */
  const calculateMobile = function calculateMobile() {
    // Make the header sticky if it isn't already.
    if (!isHeaderSticky) {
      fixElement(header);
      header.style.paddingTop = `${headerRect.top}px`;
      header.style.width = '100%';
      header.classList.add('is-sticky');
      isHeaderSticky = true;
      pianoHigh.classList.add('is-sticky');
      const pianoHighOffset = headerEyebrow.offsetHeight + brandBar.offsetHeight;
      pianoHigh.style.top = `${pianoHighOffset}px`;
    }
  };

  /**
   * This function is responsible for the sticky header behaviour on desktop.
   * Functionality is:
   *  The banner ad is sticky until the bottom of it touches the top of the nav.
   *  The banner ad freezes in place and scrolls up with the page.
   *  When the nav touches the top of the screen, it becomes sticky.
   */
  const calculateDesktop = function calculateDesktop() {
    // if the bottom of the banner is above the top of the navbar, make it sticky.
    bannerAdRect = bannerAdWrapper.getBoundingClientRect();
    bannerHeight = bannerAdWrapper.clientHeight;
    navRect = nav.getBoundingClientRect();
    fixNavScrollPos = (bannerHeight + headerInner.clientHeight) - navRect.height;

    // if the nav hits the top of the page, make it stick there.
    if (navRect.top <= 0 && bannerAdRect.bottom <= 0 && !isNavSticky) {
      let pianoHighOffset = 0;
      isNavSticky = true;
      if (headerEyebrow) {
        const headerNavOffset = headerEyebrow.offsetHeight - 1;
        headerNav.style.top = `${headerNavOffset}px`;
        headerNav.style.zIndex = '-1';  // so we can click account settings dropdown
        headerEyebrow.classList.add('is-sticky');
        pianoHighOffset += headerNavOffset; // add eyebrow height
      }
      headerNav.classList.add('is-sticky');
      header.classList.add('is-sticky');
      pianoHighOffset += headerNav.offsetHeight - 1; // add nav height
      pianoHigh.style.top = `${pianoHighOffset}px`;
      pianoHigh.classList.add('is-sticky');
    } else if (window.pageYOffset < fixNavScrollPos && isNavSticky) {
      isNavSticky = false;
      headerNav.classList.remove('is-sticky');
      header.classList.remove('is-sticky');
      pianoHigh.classList.remove('is-sticky');
      pianoHigh.style.top = '0px';
      if (headerEyebrow) {
        headerNav.style.zIndex = 'initial';
        headerEyebrow.classList.remove('is-sticky');
      }
    }
  };

  /**
   * Set up sticky behaviour on mobile.
   * The entire header is sticky until the first in-page MPU is encountered.
   */
  const setupMobile = function setupMobile() {
    // get header dimensions and push body content down by the size of the header.
    headerRect = header.getBoundingClientRect();
    body.style.paddingTop = `${header.clientHeight}px`;

    // Calculate positions.
    calculateMobile();

    // Recalculate positions on page scroll.
    document.addEventListener('scroll', () => {
      calculateMobile();
    });
  };

  /**
   * Set up sticky behaviour on desktop.
   */
  const setupDesktop = function setupDesktop() {
    // Calculate positions.
    calculateDesktop();
    // Recalculate positions on page scroll.
    document.addEventListener('scroll', () => {
      calculateDesktop();
    });
  };

  /**
   * Init module with given options.
   * Set up element references.
   *
   * @param {Object} [options={}] - overrides for the default settings object.
   */
  const init = function init(options = {}) {
    settings = Object.assign(defaults, options);

    // Set up element selectors.
    body = document.querySelector('body');
    bannerAd = document.querySelector(settings.css.selectors.bannerAd);
    bannerAdContainer = document.querySelector(settings.css.selectors.bannerAdContainer);
    bannerAdWrapper = document.querySelector(settings.css.selectors.bannerAdWrapper);
    headerEyebrow = document.querySelector(settings.css.selectors.headerEyebrow);
    nav = document.querySelector(settings.css.selectors.nav);
    navWrapper = document.querySelector(settings.css.selectors.navWrapper);
    header = document.querySelector(settings.css.selectors.header);
    headerInner = document.querySelector(settings.css.selectors.headerInner);
    headerNav = document.querySelector(settings.css.selectors.headerNav);
    brandBar = document.querySelector(settings.css.selectors.brandBar);
    pianoHigh = document.querySelector(settings.css.selectors.pianoHigh);

    // Exit gracefully if a required element is missing.
    if (!bannerAd || !bannerAdContainer || !bannerAdWrapper || !nav || !navWrapper || !header ||
      !headerInner) {
      return false;
    }

    // This could be wrapped in matchMedia queries to work responsively.
    if (isTabletDown()) {
      setupMobile();
    } else {
      setupDesktop();
    }

    return true;
  };

  return {
    init,
  };
})();
