/**
 * A text input with a clear button
 *
 * @module components/TextboxClearableUI
 * @author Daniel Harte <daniel.harte@immediate.co.uk>
 * @version 1.0
 */

export default (function TextboxClearable() {
  const defaults = {
    selectors: {
      container: '.js-input-textbox-clearable',
      child: {
        input: '.js-input-textbox-clearable-text',
        clearBtn: '.js-input-textbox-clearable-clear',
      },
    },
  };

  // constructor
  const TextboxClearableUI = function TextboxClearableUI(options) {
    this.settings = Object.assign({}, defaults, options);
    const container = document.querySelector(this.settings.selectors.container);
    if (container) {
      // bind event callbacks
      this.change = this.change.bind(this);
      this.clear = this.clear.bind(this);

      // grab references to child nodes
      this.settings.input = container.querySelector(this.settings.selectors.child.input);
      this.settings.clearBtn = container.querySelector(this.settings.selectors.child.clearBtn);

      if (this.settings.input && this.settings.clearBtn) {
        this.settings.input.addEventListener('input', this.change);
        this.settings.clearBtn.addEventListener('click', this.clear);
      }
    }
  };

  // handles an input text change
  TextboxClearableUI.prototype.change = function change() {
    if (!this.settings.input) return;
    if (this.settings.input.value === '') {
      this.settings.clearBtn.style.display = 'none';
    } else {
      this.settings.clearBtn.style.display = 'block';
    }
  };

  // clears the value
  TextboxClearableUI.prototype.clear = function clear() {
    this.settings.clearBtn.style.display = 'none';
    this.settings.input.value = '';
    this.settings.input.dispatchEvent(new Event('input'));
    this.settings.input.focus();
  };

  TextboxClearableUI.prototype.destroy = function destroy() {
    this.settings.clearBtn.removeEventListener('click', this.clear);
    this.settings.input.removeEventListener('input', this.change);
  };

  return TextboxClearableUI;
}());
