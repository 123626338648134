import lazySizes from 'lazysizes';

lazySizes.cfg.lazyClass = 'js-lazyload';
lazySizes.cfg.loadedClass = 'is-loaded';

export default (() => {
  const init = () => {
    lazySizes.init();
  };

  return {
    init,
  };
})();
