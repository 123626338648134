import { createUIMap } from 'js/utils';
import collapse from 'js/modules/collapse';
import CollapseFixed from 'js/modules/collapse-fixed';

/**
 * Make an element collapsible on click and via a programmatic API.
 *
 * The JavaScript handler should be added to a container class. The first
 * element found in the container with an aria-hidden attribute will become the
 * collapsible element and the first found with the aria-expanded attribute
 * will become the element that can be clicked to control the collapsing.
 *
 * The API essentially toggles the values or aria-expanded and aria-hidden,
 * which can then be styled accordingly with CSS.
 * @module components/collapse-ui.js
 * @see module:view-model/common-vm
 */

export default {
  uimap: createUIMap({
    container: '.js-collapse',
    toggle: '.js-collapse [aria-expanded]',
    collapsible: '.js-collapse [aria-hidden]',
  }),

  /**
   * Initialise.
   */
  init() {
    this.uimap.$$container.forEach((containerEl) => {
      const toggleEl = containerEl.querySelector(this.uimap.toggle);
      const collapsibleEl = containerEl.querySelector(this.uimap.collapsible);
      const collapsedHeight = containerEl.getAttribute('data-collapsed-height');

      if (collapsedHeight) {
        if (containerEl.scrollHeight > collapsedHeight) {
          const collapseFixed = new CollapseFixed(containerEl, collapsibleEl, toggleEl);
          collapseFixed.init();
          return;
        }
        return;
      }

      collapse(containerEl, collapsibleEl, toggleEl);
    });
  },
};
