import { createUIMap } from 'js/utils';
import scroll from 'js/modules/scroll';

export default {
  uimap: createUIMap({
    container: '.js-scroll-link',
  }),

  /**
   * Initialise.
   */
  init() {
    this.uimap.$$container.forEach((anchorEl) => {
      const scrollTo = anchorEl.getAttribute('data-scroll-to');

      if (!scrollTo) {
        return;
      }

      const scrollTarget = document.getElementById(scrollTo);

      if (!scrollTarget) {
        return;
      }

      anchorEl.addEventListener('click', (event) => {
        event.preventDefault();
        scroll.scrollToElement(scrollTarget);
      });
    });
  },
};
